import React, { Fragment, useEffect, useState } from "react";

import {
  Paper,
  Grid,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Button,
  makeStyles
} from "@material-ui/core/";
import {
  ExpandLess,
  ExpandMore,
  AddCircle,
  List as ListIcon,
  AttachFile as AttachmentIcon,
  Public as WebtabIcon,
  VerticalSplit as GeneralIcon
} from "@material-ui/icons/";


import NavBar from "../../components/NavBar";
import PageHeader from "../../components/PageHeader";
import PageScreen from "../../components/PageScreen";
import HeaderButton from "../../components/HeaderButton";


import StaticContentList from "./components/StaticContentList";
import StaticContentListItem from "./components/StaticContentList/components/StaticContentListItem";
import StaticContent from "./components/StaticContent";
import StaticContentChoice from "./components/StaticContentChoice";

import { Link, useParams } from "react-router-dom";




// HOCs
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modulesActionCreators from "../../data/modules/actions";


import { ObjectID } from 'bson';
import { ReactSortable } from "react-sortablejs";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  newList: {
    marginTop: 15,
    marginBottom: 25,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const baseModules = () => [
  {
    _id: new ObjectID(),
    title: { es: 'Lista' },
    contentType: 'list',
    content: {},
    subcontents: []
  },
  {
    _id: new ObjectID(),
    title: { es: 'Archivo adjunto' },
    contentType: 'attachment',
    content: { es: '' },
    subcontents: []
  },
  {
    _id: new ObjectID(),
    title: { es: 'Enlace externo' },
    contentType: 'external',
    content: { es: '' },
    subcontents: []
  },
  {
    _id: new ObjectID(),
    title: { es: 'Contenido libre' },
    contentType: 'general',
    content: {},
    subcontents: []
  }
];

const StaticContents = (props) => {

  let { moduleId } = useParams();
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [contents, setContents] = useState([]);
  const [selectedPath, setSelectedPath] = useState(null);

  useEffect(() => {
    props.actions.modules.getItem(moduleId);
  }, []);

  useEffect(() => {
   if (props.modules.selectedItem && props.modules.selectedItem.contents) {
     setContents(props.modules.selectedItem.contents)
   }
  }, [props.modules.selectedItem, props.modules.selectedItem.contents]);
  console.log("THE CONTENTS", contents);

  const handleSubmit = () => {
    //alert(JSON.stringify(contents));
    props.actions.modules.patch(props.modules.selectedItem, {
      //...props.modules.selectedItem,
      contents: contents
    },
    {
      query: {
        _type: props.modules.selectedItem._type
      }
    }).then(r => {
      alert("Contenidos guardados")
    }).catch(e => alert(JSON.stringify(e)));
  };

  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
          { title: props.selectedApp.name, path: `/${props.selectedApp.slug}/` },
          { title: props.modules.selectedItem.name },
        ]}
      />
      <PageScreen className="ListPage">
        <PageHeader
          title={props.modules.selectedItem.name}
          renderActions={() => {} }
        />
        <Grid container  spacing={2}>
          <Grid item xs={12}>
            <ReactSortable
              list={baseModules()}
              setList={() => {}}
              group={{ name: 'groupName', pull: 'clone'}}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              {baseModules().map((bm, i) => (
                <StaticContentChoice clone key={'ss'} content={bm} setSelectedPath={() => {}} setContents={() => {}} basePath={`[${i}]`} index={i} />
              ))}
            </ReactSortable>
          </Grid>
          <Grid item xs={6}>
            <StaticContentList setSelectedPath={setSelectedPath} contents={contents} setContents={setContents} />
          </Grid>
          <Grid item xs={6}>
            {selectedPath ?
              <StaticContent contents={contents} setContents={setContents} selectedPath={selectedPath} />
            : <div style={{ height: '100%', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}><h3>Selecciona o crea un contenido</h3></div> }

          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>Guardar cambios</Button>
          </Grid>
        </Grid>

      </PageScreen>
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    modules: state.data.modules,
    selectedApp: state.data.apps.selected,
    services: {
      app: state.services.app
    },
    defaultLang: 'es'
  }),
  (dispatch) => ({
    actions: {
      modules: bindActionCreators(modulesActionCreators, dispatch),
    },
  })
)(StaticContents);
