import React, { useState } from 'react';
import { Paper, Box, Grid, Button, Typography } from '@material-ui/core/'

import { Routes, Route, NavLink } from "react-router-dom";

import FeathersHelper from "../../helpers/feathers";

import PageScreen from '../../components/PageScreen';
import RoundedForm from '../../components/RoundedForm';

export default props => {

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const handleSubmit = () => {

    if (firstName == "") {
      alert("El nombre es obligatorio")
    } else if (lastName == "") {
      alert("Los apellidos son obligatorios")
    }else if (email == '') {
      alert("El e-mail es obligatorio");
    } else if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
      alert("El formato del e-mail es incorrecto")
    } else if (password == "") {
      alert("La contraseña es obligatoria")
    } else if (password != passwordConfirmation) {
      alert("Las contraseñas no coinciden")
    } else {
      FeathersHelper.create("admins", {
        firstName,
        lastName,
        email,
        password
      })
        .then(() => {
          FeathersHelper.authenticate(email, password).then( r => {
            window.location = '/';
          })

        })
        .catch(e => {
          console.log("Authj error", e);
          alert(JSON.stringify(e.errors));
        });
    }


  };

  return (
    <PageScreen className="Login" noContainer>
      <Grid container spacing={0} style={{ flex: 1 }}>
        <Grid item xs={8} lg={9} style={{ flex: 1 }}>
          <Grid container spacing={0} justify="center" alignItems="center" style={{ height: '100%' }}>
            <Grid item xs={4}>
              <Typography component="h2" variant="h2" align="center">
                Registro
              </Typography>
              <Typography component="h6" variant="h6" align="center">
                Crea tu app en menos de un minuto
              </Typography>
              <RoundedForm style={{ marginTop: 25, marginBottom: 25 }}>
                <input type="text" placeholder="Nombre" value={firstName} onChange={e => setFirstName(e.target.value)} />
                <input type="text" placeholder="Apellidos" value={lastName} onChange={e => setLastName(e.target.value)} />
                <input type="text" placeholder="E-mail" value={email} onChange={e => setEmail(e.target.value)} />
                <input type="password" placeholder="Contraseña" value={password} onChange={e => setPassword(e.target.value)} />
                <input type="password" placeholder="Repetir contraseña" value={passwordConfirmation} onChange={e => setPasswordConfirmation(e.target.value)} />
              </RoundedForm>
              <Button onClick={handleSubmit} variant="contained" color="primary" size="large" fullWidth>Registro</Button>
              <Typography component="p" variant="p" align="center" className="Hint">
                ¿Ya estás registrado? <NavLink
                  to={`/login`}
                >
                  Accede
                </NavLink>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} lg={3} className="YellowBar">
          <div className="Overlay"></div>
        </Grid>
      </Grid>
    </PageScreen>
  );
}