import React, { } from 'react';

import { Grid, TableContainer, Paper } from "@material-ui/core";

import { DataGrid, GridToolbar, GridFilterPanel, esES } from '@material-ui/data-grid';



export default props => {


  return (
      <Paper style={{ marginBottom: 20, overflowX: 'scroll' }}>
        <DataGrid
          autoHeight
          components={{
            Toolbar: GridToolbar,
            FilterPanel: GridFilterPanel
          }}
          checkboxSelection
          rows={props.items}
          columns={props.columnDefs}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          pageSize={10}
          pagination
        />
      </Paper>
  );
}