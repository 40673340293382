import React, { Fragment, useEffect, useState } from "react";

import { Switch, Chip, IconButton } from "@material-ui/core/";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons/";

import NavBar from "../../components/NavBar";
import PageHeader from "../../components/PageHeader";
import PageScreen from "../../components/PageScreen";
import DataView from "../../components/DataView";
import RightModal from "../../components/RightModal/v2";

import { useConfirm } from "material-ui-confirm";

import { Link, useParams } from "react-router-dom";

// HOCs
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modulesActionCreators from "../../data/modules/actions";
import HeaderButton from "../../components/HeaderButton";

import Moment from "react-moment";
import "moment-timezone";
import nativeMoment from "moment-timezone";
import "moment/locale/es";

const Appointments = (props) => {


  const MODAL_MANIFEST = [
    [
      {
        key: "startDate",
        timezoneKey: "timezone",
        type: "datetime",
        label: "Inicio del acto",
        help: "Inicio del acto.",
      },
    ],
    [
      {
        key: "endDate",
        timezoneKey: "timezone",
        type: "datetime",
        label: "Fin del acto",
        help: "Fin del acto.",
      },
    ],
    [
      {
        key: "title",
        multilang: true,
        type: "string",
        label: "Título del acto",
        help: "Título del acto.",
      },
    ],
    [
      {
        key: "body",
        multilang: true,
        type: "text",
        label: "Descripción del acto",
        help: "Descripción del acto.",
      },
    ],
    [
      {
        key: "roomId",
        type: "roomPicker",
        label: "Sala",
        help: "Elige una sala virtual donde enlazar el acto.",
      },
    ],
  ];
  const MODAL_LANG_MANIFEST = [

  ];

  let { moduleId } = useParams();

  useEffect(() => {
    props.actions.modules.getItem(moduleId);
  }, []);

  const [currentItem, setCurrentItem] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const handleNew = () => {
    setCurrentItem({
      timezone: 'Europe/Madrid'
    });
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    const newItem = { ...item };
    if (!newItem.timezone) {
      newItem.timezone = 'Europe/Madrid';
    }
    // if (newItem.startDate) {
    //   newItem.startDate = nativeMoment.tz(newItem.startDate, newItem.timezone||'Europe/Madrid').format('YYYY-MM-DDTHH:mm:ss');
    // }
    // if (newItem.endDate) {
    //   newItem.endDate = nativeMoment.tz(newItem.endDate, newItem.timezone||'Europe/Madrid').format('YYYY-MM-DDTHH:mm:ss');
    // }
    setCurrentItem(newItem);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setCurrentItem({});
  };

  const confirm = useConfirm();

  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
          { title: props.selectedApp.name, path: `/${props.selectedApp.slug}` },
          { title: props.modules.selectedItem.name },
        ]}
      />
      <PageScreen className="ListPage">
        <PageHeader
          title="Agenda"
          renderActions={() => (
            <Fragment>
              <HeaderButton onClick={handleNew}>+ Nuevo acto</HeaderButton>
            </Fragment>
          )}
        />
        <DataView
          items={(props.modules.selectedItem.appointments || []).sort( (a,b) => a.startDate > b.startDate ? 1 : -1)}
          columnDefs={[
            { ref: "title", align: "left" },
            { ref: "startDate", align: "left" },
            { ref: "endDate", align: "left" },
            { ref: "actions", align: "left" },
          ]}
          renderHeader={(ref) => {
            switch (ref) {
              case "title":
                return "Título";
              case "startDate":
                return "Inicio";
              case "endDate":
                return "Fin";
              case "actions":
                return "Acciones";
              default:
                return null;
            }
          }}
          renderCell={(ref, item) => {
            switch (ref) {
              case "title":
                return item[ref][props.defaultLang];
              case "startDate":
              case "endDate":
                return nativeMoment.tz(item[ref], item.timezone||'Europe/Madrid').format('L HH:mm') + ` (${item.timezone||'Europe/Madrid'})`;
              case "actions":
                return (
                  <Fragment>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      className="EditButton"
                      onClick={() => handleEdit(item)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      className="DeleteButton"
                      onClick={() => {
                        confirm({
                          title: "¿Eliminar acto?",
                          description: `Esta acción eliminará por completo el
                          acto.
                          Esta acción es permenente y no tiene marcha atrás.`,
                          confirmationText: "Eliminar",
                          confirmationButtonProps: { variant: "contained" },
                          cancellationText: "Cancelar",
                          cancellationButtonProps: { variant: "contained" },
                        })
                          .then(() => {
                            props.actions.modules.removeAppointment(moduleId, item._id);
                          })
                          .catch(() => {});
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Fragment>
                );
              default:
                return null;
            }
          }}
          empty={{
            title: "Añade actos a tu agenda",
            body:
              "Construye rápidamente y previsualiza al instante todas las funciones están incluidas, solo cree y publique go live en minutos y comience a incorporar asistentes",
            cta: "+ Crear nuevo acto",
            action: handleNew,
          }}
        />
      </PageScreen>
      <RightModal
        newTitle="Crear acto"
        editTitle="Editar acto"
        open={modalOpen}
        currentItem={currentItem}
        handleClose={handleClose}
        manifest={MODAL_MANIFEST}
        langManifest={MODAL_LANG_MANIFEST}
        createAction={(payload) => {
          console.log("PAYLOAD", payload)
          return props.actions.modules.createAppointment(moduleId, payload)
        }}
        patchAction={(currentItem, payload) =>
          props.actions.modules.patchAppointment(moduleId, payload)
        }
      />
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    modules: state.data.modules,
    services: {
      app: state.services.app
    },
    defaultLang: 'es'
  }),
  (dispatch) => ({
    actions: {
      modules: bindActionCreators(modulesActionCreators, dispatch),
    },
  })
)(Appointments);
