import React, {} from 'react';

import {
  Box,
  Grid,
  InputLabel,
  Button,
  Icon
} from "@material-ui/core";

import StringField from './components/StringField';
import AutocompleteField from './components/AutocompleteField';
import TextField from './components/TextField';
import WysiwygField from './components/WysiwygField';
import SwitchField from './components/SwitchField';
import SelectField from './components/SelectField';
import DateTimeField from './components/DateTimeField';
import RoomPicker from './components/RoomPicker';
import MultiAudienceField from './components/MultiAudienceField';
import MultiCheckField from './components/MultiCheckField';
import ImageField from './components/ImageField';
import AudienceVisibilityField from './components/AudienceVisibilityField';
import IconField from './components/IconField';
import StringArrayField from './components/StringArrayField';

import { makeStyles } from "@material-ui/core/styles";

const _ = require("lodash");

const useStyles = makeStyles((theme) => ({
  formBox: {
    backgroundColor: '#FFFFFF',
    border: "1px solid #EEEEEF",
    borderRadius: 10,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  noBox: {
    border: 'none'
  },
  nestedWell: {
    backgroundColor: '#F7F7F8'
  },
  nestedWellWhite: {

  },
  addItemButton: {
    //borderWidth: 1,
    borderColor: 'red',
    //borderStyle: 'dashed',
    borderWidth: 0,
    alignSelf: 'stretch',
    minHeight: 62,
    fontSize: 14,
    color: '#716B68',
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23C7C7CAFF' stroke-width='1' stroke-dasharray='5%2c 5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    borderRadius: 10,
    "&:hover": {
      background: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23C7C7CAFF' stroke-width='1' stroke-dasharray='5%2c 5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    },
  },
  deleteItemButton: {
    position: 'absolute',
    top: 0,
    right: -8,
    borderWidth: 0,
    background: 'none',
    cursor: 'pointer',
    outline: 'none'
  },
  formBoxHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  label: {
    textTransform: "uppercase",
    color: "#686E71",
    fontSize: "0.916rem",
  },
  helperText: {
    color: "#B4B4B7",
    fontSize: "0.6875rem",
  },
  error: {
    borderColor: "#FF6666",
    color: "#FF6666",
  },
}));

const Field = props => {

  const classes = useStyles();

  const { definition, currentLang, erroredFields, valueForPath, setValueForPath, basePath = [] } = props;

  const { type, children, key, multilang, add } = definition;

  let horizontal = false;

  if (type == 'switcha') horizontal = true;

  //error={erroredFields[i.key]}

  const errorHelper = () => {
    return _.get(erroredFields, `${[ ...basePath, key].join('.')}`); //erroredFields
  };

  const valueHelper = (k = key) => {
    console.log(`${[ ...basePath, k].join('.')}`)
    if (multilang) return valueForPath(`${[ ...basePath, k].join('.')}.${currentLang}`) || ''
    else return valueForPath(`${[ ...basePath, k].join('.')}`)
  };

  const onCangeHelper = (v, k = key) => {
    if (multilang) setValueForPath(`${[ ...basePath, k].join('.')}.${currentLang}`, v);
    else setValueForPath(`${[ ...basePath, k].join('.')}`, v);
    if (props.definition.onChange) props.definition.onChange(v);
  };

  const addChild = () => {
    const v = valueForPath(`${[ ...basePath, key].join('.')}`);
    if (v) setValueForPath(`${[ ...basePath, key].join('.')}`, [ ...v, {}])
    else setValueForPath(`${[ ...basePath, key].join('.')}`, [{}])
  }

  const removeChild = (i) => {
    const v = valueForPath(`${[ ...basePath, key].join('.')}`);
    setValueForPath(`${[ ...basePath, key].join('.')}`, v.filter((v,j) => j !== i))
  }



  const renderField = () => {
    switch (props.definition.type) {
      case "string":
        return (
          <StringField
            currentLang={currentLang}
            definition={definition}
            classes={classes}
            error={errorHelper()}
            value={valueHelper}
            onChange={e => onCangeHelper(e.target.value)}
          />
        );
      case "autocomplete":
        return (
          <AutocompleteField
            currentLang={currentLang}
            definition={definition}
            classes={classes}
            error={errorHelper()}
            value={valueHelper}
            onChange={v => onCangeHelper(v)}
          />
        );
      case "text":
        return (
          <TextField
            currentLang={currentLang}
            definition={definition}
            classes={classes}
            error={errorHelper()}
            value={valueHelper}
            onChange={e => onCangeHelper(e.target.value)}
          />
        );
      case "wysiwyg":
        return (
          <WysiwygField
            currentLang={currentLang}
            definition={definition}
            classes={classes}
            error={errorHelper()}
            value={valueHelper}
            onChange={e => onCangeHelper(e.target.value)}
          />
        );
      case "switch":
        return (
          <SwitchField
            currentLang={currentLang}
            definition={definition}
            classes={classes}
            error={errorHelper()}
            value={valueHelper}
            onChange={e => onCangeHelper(e.target.checked)}
          />
        );
      case "select":
        return (
          <SelectField
            currentLang={currentLang}
            definition={definition}
            classes={classes}
            error={errorHelper()}
            value={valueHelper}
            onChange={e => onCangeHelper(e.target.value)}
          />
        );
      case "roomPicker":
        return (
          <RoomPicker
            definition={definition}
            classes={classes}
            error={errorHelper()}
            value={valueHelper}
            onChange={e => onCangeHelper(e.target.value)}
          />
        );
      case "datetime":
        return (
          <DateTimeField
            currentLang={currentLang}
            definition={definition}
            classes={classes}
            error={errorHelper()}
            value={valueHelper}
            onChange={e => onCangeHelper(e.target.value)}
            timezoneValue={valueHelper('timezone')}
            onTimezoneChange={e => onCangeHelper(e.target.value, 'timezone')}
          />
        );
      case "multiAudience":
        return (
          <MultiAudienceField
            definition={definition}
            classes={classes}
            error={errorHelper()}
            value={valueHelper}
            onChange={e => onCangeHelper(e.target.value)}
          />
        );
      case "multiCheck":
        return (
          <MultiCheckField
            definition={definition}
            classes={classes}
            error={errorHelper()}
            value={valueHelper}
            onChange={e => onCangeHelper(e.target.value)}
          />
        );
      case "image":
        return (
          <ImageField
            definition={definition}
            classes={classes}
            error={errorHelper()}
            value={valueHelper}
            onChange={e => onCangeHelper(e.target.value)}
          />
        );
      case "audienceVisibility":
        return (
          <AudienceVisibilityField
            definition={definition}
            classes={classes}
            error={errorHelper()}
            value={valueHelper}
            onChange={e => onCangeHelper(e.target.value)}
          />
        );
      case "icon":
        return (
          <IconField
            definition={definition}
            classes={classes}
            error={errorHelper()}
            value={valueHelper}
            onChange={e => onCangeHelper(e.target.value)}
          />
        );
      case "stringArray":
        return (
          <StringArrayField
            definition={definition}
            classes={classes}
            error={errorHelper()}
            value={valueHelper}
            onChange={e => onCangeHelper(e.target.value)}
          />
        );
    }
  }

  if ( type == 'nested') {

    const path = [ ...basePath, key ];

    return (
      <Grid item md={props.size} id={props.definition.id}>
        <Box
          p={props.noPadding ? 0 : 2}
          id={props.id}
          className={`${classes.formBox} ${props.noBox ? classes.noBox : null} ${
            props.error ? classes.error : null
          } ${basePath.length == 0 ? classes.nestedWell : classes.nestedWellWhite}`}
        >

          {basePath.length != 0 && <Box>
            <InputLabel className={["MuiInputLabel-shrink", classes.label]} mt={2}>
              {definition.label}
            </InputLabel>
          </Box>}

          {(valueHelper()||[]).map((e, i) => (
            <Box
              p={2}
              my={2}
              id={props.id}
              className={`${classes.formBox} ${
                props.error ? classes.error : null
              }`}
            >
              <Grid container spacing={2} style={{ position: 'relative' }}>
                {
                  children.map((row) => {
                    const size = row.length == 1 ? 12 : 6;
                    return row.map((c) => (
                      <Field
                        {...props}
                        id={c.multilang ? `field_${path}_${currentLang}` : `field_${path}`}
                        definition={c}
                        size={size}
                        basePath={[ ...basePath, definition.key, i ]}
                        erroredFields={erroredFields}
                        valueForPath={valueForPath}
                        setValueForPath={setValueForPath}
                        noBox
                        noPadding
                      />
                    ));
                  })
                }
                <button className={classes.deleteItemButton} onClick={() => removeChild(i)}><Icon style={{ color: '#B4B4B7' }}>clear</Icon></button>
              </Grid>

            </Box>
          ))}
          <Button variant="outlined" className={classes.addItemButton} onClick={addChild}>+ {add}</Button>
        </Box>
      </Grid>
    );

  } else {

    return (
      <Grid item md={props.size} id={props.definition.id}>
        <Box
          p={ props.noPadding ? 0 : 2}
          id={props.id}
          className={`${classes.formBox} ${props.noBox ? classes.noBox : null} ${horizontal && classes.formBoxHorizontal} ${
            props.error ? classes.error : null
          }`}
        >
          {renderField()}
        </Box>
      </Grid>
    );

  }


};

export default Field;