import React, { useState } from 'react';
import { Paper, Box, Grid, Button, Typography } from '@material-ui/core/'

import { Routes, Route, NavLink } from "react-router-dom";


import FeathersHelper from "../../helpers/feathers";

import PageScreen from '../../components/PageScreen';
import RoundedForm from '../../components/RoundedForm';

export default props => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = () => {
    FeathersHelper.authenticate(email, password)
      .then(() => {
        setEmail("");
        setPassword("");
      })
      .catch(e => {
        console.log("Authj error", e);
        setPassword("");
        alert("Las credenciales son incorrectas");
      });
  };

  return (
    <PageScreen className="Login" noContainer>
      <Grid container spacing={0} style={{ flex: 1 }}>
        <Grid item xs={8} lg={9} style={{ flex: 1 }}>
          <Grid container spacing={0} justify="center" alignItems="center" style={{ height: '100%' }}>
            <Grid item xs={4}>
              <Typography component="h2" variant="h2" align="center">
                Acceder
              </Typography>
              <Typography component="h6" variant="h6" align="center">
                Crea tu app en menos de un minuto
              </Typography>
              <RoundedForm style={{ marginTop: 25, marginBottom: 25 }}>
                <input type="text" placeholder="E-mail" value={email} onChange={e => setEmail(e.target.value)} />
                <input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
              </RoundedForm>
              <Button onClick={handleSubmit} variant="contained" color="primary" size="large" fullWidth>Acceder</Button>
              <Typography component="p" variant="p" align="center" className="Hint">
                ¿Aún no estás registrado? <NavLink
                  to={`/signup`}
                >
                  Hazlo aquí
                </NavLink>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} lg={3} className="YellowBar">
          <div className="Overlay"></div>
        </Grid>
      </Grid>
    </PageScreen>
  );
}