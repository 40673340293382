import React, { Fragment, useEffect, useState } from "react";

import { Switch, Chip, IconButton } from "@material-ui/core/";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons/";

import NavBar from "../../components/NavBar";
import PageHeader from "../../components/PageHeader";
import PageScreen from "../../components/PageScreen";
import DataView from "../../components/DataView";
import RightModal from "../../components/RightModal/v2";

import { useConfirm } from "material-ui-confirm";

import { Link, useParams } from "react-router-dom";

// HOCs
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modulesActionCreators from "../../data/modules/actions";
import * as postsActionCreators from "../../data/posts/actions";
import HeaderButton from "../../components/HeaderButton";

import Moment from "react-moment";
import "moment-timezone";
import nativeMoment from "moment-timezone";
import "moment/locale/es";
import { ScaledImage } from "../../components/ScaledImage";

import FeathersHelper from "../../helpers/feathers";

const BASE_QUERY = {
  $limit: 10,
  $sort: {
    pinned: -1,
    createdAt: -1,
  },
};

const Posts = (props) => {



  let { moduleId } = useParams();

  useEffect(() => {
    props.actions.modules.getItem(moduleId);
    props.actions.posts.findItems({ query: { moduleId }, $sort: { createdAt: -1 } });
  }, []);

  useEffect(() => {
    FeathersHelper.find('modules', { query: { _type: 'votingModules', $limit: -1 } }).then( r => {
      const _questions = [];
      r.map( m => {
        m.votingBlocks.map(vb => {
          vb.questions.map(q => {
            if (q.type == 'options') {
              _questions.push({
                key: q._id,
                label: `${m.name} - ${vb.title.es} - ${q.title.es}`
              });
            }
          });
        });
      });
      setQuestions(_questions);

      setModalManifest([
        [
          {
            key: "postType",
            type: "select",
            label: "Tipo de post",
            options: [
              { key: 'user', label: 'Usuario' },
              { key: 'survey', label: 'Votación' },
            ]
          },
        ],
        [
          {
            key: "reference",
            type: "select",
            label: "Pregunta",
            options: _questions,
            help: "Módulo al que llevará la notificación",
          },
        ],
        // [
        //   {
        //     key: "content",
        //     type: "text",
        //     label: "Contenido",
        //     help: "Contenido del post.",
        //   }
        // ]
      ]);
    });
  }, []);

  const [questions, setQuestions] = useState([]);
  const [modalManifest, setModalManifest] = useState([]);
  const [currentItem, setCurrentItem] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const handleNew = () => {
    setCurrentItem({
      moduleId,
      postType: 'survey'
    });
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    const newItem = { ...item };

    setCurrentItem(newItem);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setCurrentItem({});
  };

  const confirm = useConfirm();
  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
          { title: props.selectedApp.name, path: `/${props.selectedApp.slug}` },
          { title: props.modules.selectedItem.name },
        ]}
      />
      <PageScreen className="ListPage">
        <PageHeader
          title={props.modules.selectedItem.name}
          renderActions={() => (
            <Fragment>
              <HeaderButton onClick={handleNew}>
                + Nuevo post
              </HeaderButton>
            </Fragment>
          )}
        />
        <DataView
          items={props.data.posts.items}
          total={props.data.posts.total}
          loadPage={(page, perPage) => props.actions.posts.findItems({ query: {
            ...BASE_QUERY,
            $limit: 10,
            $skip: (perPage * page)
          } })}
          columnDefs={[
            { ref: "postType", align: "left" },
            { ref: "user", align: "left" },
            { ref: "media", align: "left" },
            { ref: "content", align: "left" },
            { ref: "pinned", align: "center" },
            { ref: "createdAt", align: "left" },
            { ref: "actions", align: "left" },
          ]}
          renderHeader={(ref) => {
            switch (ref) {
              case "postType":
                return "Tipo";
              case "user":
                return "Usuario/Pregunta";
              case "media":
                return "Media";
              case "content":
                return "Contenido";
              case "pinned":
                return "Pin";
              case "createdAt":
                return "Fecha";
              case "actions":
                return "Acciones";
              default:
                return null;
            }
          }}
          renderCell={(ref, item) => {
            switch (ref) {
              case "postType":
                return item.postType == 'survey' ? 'Pregunta' : 'Normal';
              case "user":
                if (item.postType == 'survey') {
                  const tq = questions.filter(q => q.key.toString() == item.reference?.toString());
                  return tq.length > 0 ? tq[0].label : '';
                } else {
                  return item.user ? `${item.user.firstName} ${item.user.lastName}` : 'Anónimo';
                }
              case "media":
                if (item.video) {
                  return 'Video';
                } else if (item.image) {
                  return <ScaledImage imgkey={item.image} width={50} height={50} />;
                } else return '-';
              case "content":
                return item[ref];
              case "pinned":
                return (
                  <Switch
                    color="primary"
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    onChange={(event) =>
                      props.actions.posts.patch(item, {
                        pinned: event.target.checked,
                      })
                    }
                    checked={item.pinned}
                  />
                );
              case "createdAt":
                return nativeMoment(item[ref]).format('L HH:mm');
              case "actions":
                return (
                  <Fragment>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      className="EditButton"
                      onClick={() => handleEdit(item)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      className="DeleteButton"
                      onClick={() => {
                        confirm({
                          title: "¿Eliminar post?",
                          description: `Esta acción eliminará por completo el post.
                          Esta acción es permenente y no tiene marcha atrás.`,
                          confirmationText: "Eliminar",
                          confirmationButtonProps: { variant: "contained" },
                          cancellationText: "Cancelar",
                          cancellationButtonProps: { variant: "contained" },
                        })
                          .then(() => {
                            props.actions.posts.remove(item);
                          })
                          .catch(() => {});
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Fragment>
                );
              default:
                return null;
            }
          }}
          empty={{
            title: "Aun no hay posts",
            body:
              "Construye rápidamente y previsualiza al instante todas las funciones están incluidas, solo cree y publique go live en minutos y comience a incorporar asistentes",
            cta: false,
            action: handleNew,
          }}
        />
      </PageScreen>
      <RightModal
        newTitle="Crear post"
        editTitle="Editar post"
        open={modalOpen}
        currentItem={currentItem}
        handleClose={handleClose}
        manifest={modalManifest}
        createAction={props.actions.posts.create}
        patchAction={props.actions.posts.patch}
      />
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    modules: state.data.modules,
    data: {
      posts: state.data.posts
    },
    services: {
      app: state.services.app
    },
    defaultLang: 'es'
  }),
  (dispatch) => ({
    actions: {
      modules: bindActionCreators(modulesActionCreators, dispatch),
      posts: bindActionCreators(postsActionCreators, dispatch),
    },
  })
)(Posts);
