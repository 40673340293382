export default [
	{ key: 'abkhazia', label: 'Abkhazia' },
	{ key: 'afghanistan', label: 'Afghanistan' },
	{ key: 'aland-islands', label: 'Aland Islands' },
	{ key: 'albania', label: 'Albania' },
	{ key: 'algeria', label: 'Algeria' },
	{ key: 'american-samoa', label: 'American Samoa' },
	{ key: 'andorra', label: 'Andorra' },
	{ key: 'angola', label: 'Angola' },
	{ key: 'anguilla', label: 'Anguilla' },
	{ key: 'antigua-and-barbuda', label: 'Antigua And Barbuda' },
	{ key: 'argentina', label: 'Argentina' },
	{ key: 'armenia', label: 'Armenia' },
	{ key: 'aruba', label: 'Aruba' },
	{ key: 'australia', label: 'Australia' },
	{ key: 'austria', label: 'Austria' },
	{ key: 'azerbaijan', label: 'Azerbaijan' },
	{ key: 'azores-islands', label: 'Azores Islands' },
	{ key: 'bahamas', label: 'Bahamas' },
	{ key: 'bahrain', label: 'Bahrain' },
	{ key: 'balearic-islands', label: 'Balearic Islands' },
	{ key: 'bangladesh', label: 'Bangladesh' },
	{ key: 'barbados', label: 'Barbados' },
	{ key: 'basque-country', label: 'Basque Country' },
	{ key: 'belarus', label: 'Belarus' },
	{ key: 'belgium', label: 'Belgium' },
	{ key: 'belize', label: 'Belize' },
	{ key: 'benin', label: 'Benin' },
	{ key: 'bermuda', label: 'Bermuda' },
	{ key: 'bhutan', label: 'Bhutan' },
	{ key: 'bolivia', label: 'Bolivia' },
	{ key: 'bonaire', label: 'Bonaire' },
	{ key: 'bosnia-and-herzegovina', label: 'Bosnia And Herzegovina' },
	{ key: 'botswana', label: 'Botswana' },
	{ key: 'brazil', label: 'Brazil' },
	{ key: 'british-columbia', label: 'British Columbia' },
	{ key: 'british-indian-ocean-territory', label: 'British Indian Ocean Territory' },
	{ key: 'british-virgin-islands', label: 'British Virgin Islands' },
	{ key: 'brunei', label: 'Brunei' },
	{ key: 'bulgaria', label: 'Bulgaria' },
	{ key: 'burkina-faso', label: 'Burkina Faso' },
	{ key: 'burundi', label: 'Burundi' },
	{ key: 'cambodia', label: 'Cambodia' },
	{ key: 'cameroon', label: 'Cameroon' },
	{ key: 'canada', label: 'Canada' },
	{ key: 'canary-islands', label: 'Canary Islands' },
	{ key: 'cape-verde', label: 'Cape Verde' },
	{ key: 'cayman-islands', label: 'Cayman Islands' },
	{ key: 'central-african-republic', label: 'Central African Republic' },
	{ key: 'ceuta', label: 'Ceuta' },
	{ key: 'chad', label: 'Chad' },
	{ key: 'chile', label: 'Chile' },
	{ key: 'china', label: 'China' },
	{ key: 'christmas-island', label: 'Christmas Island' },
	{ key: 'cocos-island', label: 'Cocos Island' },
	{ key: 'colombia', label: 'Colombia' },
	{ key: 'comoros', label: 'Comoros' },
	{ key: 'cook-islands', label: 'Cook Islands' },
	{ key: 'corsica', label: 'Corsica' },
	{ key: 'costa-rica', label: 'Costa Rica' },
	{ key: 'croatia', label: 'Croatia' },
	{ key: 'cuba', label: 'Cuba' },
	{ key: 'curacao', label: 'Curacao' },
	{ key: 'cyprus', label: 'Cyprus' },
	{ key: 'czech-republic', label: 'Czech Republic' },
	{ key: 'democratic-republic-of-congo', label: 'Democratic Republic Of Congo' },
	{ key: 'denmark', label: 'Denmark' },
	{ key: 'djibouti', label: 'Djibouti' },
	{ key: 'dominica', label: 'Dominica' },
	{ key: 'dominican-republic', label: 'Dominican Republic' },
	{ key: 'east-timor', label: 'East Timor' },
	{ key: 'ecuador', label: 'Ecuador' },
	{ key: 'egypt', label: 'Egypt' },
	{ key: 'el-salvador', label: 'El Salvador' },
	{ key: 'england', label: 'England' },
	{ key: 'equatorial-guinea', label: 'Equatorial Guinea' },
	{ key: 'eritrea', label: 'Eritrea' },
	{ key: 'estonia', label: 'Estonia' },
	{ key: 'ethiopia', label: 'Ethiopia' },
	{ key: 'european-union', label: 'European Union' },
	{ key: 'falkland-islands', label: 'Falkland Islands' },
	{ key: 'faroe-islands', label: 'Faroe Islands' },
	{ key: 'fiji', label: 'Fiji' },
	{ key: 'finland', label: 'Finland' },
	{ key: 'france', label: 'France' },
	{ key: 'french-polynesia', label: 'French Polynesia' },
	{ key: 'gabon', label: 'Gabon' },
	{ key: 'galapagos-islands', label: 'Galapagos Islands' },
	{ key: 'gambia', label: 'Gambia' },
	{ key: 'georgia', label: 'Georgia' },
	{ key: 'germany', label: 'Germany' },
	{ key: 'ghana', label: 'Ghana' },
	{ key: 'gibraltar', label: 'Gibraltar' },
	{ key: 'greece', label: 'Greece' },
	{ key: 'greenland', label: 'Greenland' },
	{ key: 'grenada', label: 'Grenada' },
	{ key: 'guam', label: 'Guam' },
	{ key: 'guatemala', label: 'Guatemala' },
	{ key: 'guernsey', label: 'Guernsey' },
	{ key: 'guinea', label: 'Guinea' },
	{ key: 'guinea-bissau', label: 'Guinea Bissau' },
	{ key: 'haiti', label: 'Haiti' },
	{ key: 'hawaii', label: 'Hawaii' },
	{ key: 'honduras', label: 'Honduras' },
	{ key: 'hong-kong', label: 'Hong Kong' },
	{ key: 'hungary', label: 'Hungary' },
	{ key: 'iceland', label: 'Iceland' },
	{ key: 'india', label: 'India' },
	{ key: 'indonesia', label: 'Indonesia' },
	{ key: 'iran', label: 'Iran' },
	{ key: 'iraq', label: 'Iraq' },
	{ key: 'ireland', label: 'Ireland' },
	{ key: 'isle-of-man', label: 'Isle Of Man' },
	{ key: 'israel', label: 'Israel' },
	{ key: 'italy', label: 'Italy' },
	{ key: 'ivory-coast', label: 'Ivory Coast' },
	{ key: 'jamaica', label: 'Jamaica' },
	{ key: 'japan', label: 'Japan' },
	{ key: 'jersey', label: 'Jersey' },
	{ key: 'jordan', label: 'Jordan' },
	{ key: 'kazakhstan', label: 'Kazakhstan' },
	{ key: 'kenya', label: 'Kenya' },
	{ key: 'kosovo', label: 'Kosovo' },
	{ key: 'kuwait', label: 'Kuwait' },
	{ key: 'kyrgyzstan', label: 'Kyrgyzstan' },
	{ key: 'laos', label: 'Laos' },
	{ key: 'latvia', label: 'Latvia' },
	{ key: 'lebanon', label: 'Lebanon' },
	{ key: 'lesotho', label: 'Lesotho' },
	{ key: 'liberia', label: 'Liberia' },
	{ key: 'libya', label: 'Libya' },
	{ key: 'liechtenstein', label: 'Liechtenstein' },
	{ key: 'lithuania', label: 'Lithuania' },
	{ key: 'luxembourg', label: 'Luxembourg' },
	{ key: 'macao', label: 'Macao' },
	{ key: 'madagascar', label: 'Madagascar' },
	{ key: 'madeira', label: 'Madeira' },
	{ key: 'malawi', label: 'Malawi' },
	{ key: 'malaysia', label: 'Malaysia' },
	{ key: 'maldives', label: 'Maldives' },
	{ key: 'mali', label: 'Mali' },
	{ key: 'malta', label: 'Malta' },
	{ key: 'marshall-island', label: 'Marshall Island' },
	{ key: 'martinique', label: 'Martinique' },
	{ key: 'mauritania', label: 'Mauritania' },
	{ key: 'mauritius', label: 'Mauritius' },
	{ key: 'melilla', label: 'Melilla' },
	{ key: 'mexico', label: 'Mexico' },
	{ key: 'micronesia', label: 'Micronesia' },
	{ key: 'moldova', label: 'Moldova' },
	{ key: 'monaco', label: 'Monaco' },
	{ key: 'mongolia', label: 'Mongolia' },
	{ key: 'montenegro', label: 'Montenegro' },
	{ key: 'montserrat', label: 'Montserrat' },
	{ key: 'morocco', label: 'Morocco' },
	{ key: 'mozambique', label: 'Mozambique' },
	{ key: 'myanmar', label: 'Myanmar' },
	{ key: 'namibia', label: 'Namibia' },
	{ key: 'nauru', label: 'Nauru' },
	{ key: 'nepal', label: 'Nepal' },
	{ key: 'netherlands', label: 'Netherlands' },
	{ key: 'new-zealand', label: 'New Zealand' },
	{ key: 'nicaragua', label: 'Nicaragua' },
	{ key: 'niger', label: 'Niger' },
	{ key: 'nigeria', label: 'Nigeria' },
	{ key: 'niue', label: 'Niue' },
	{ key: 'norfolk-island', label: 'Norfolk Island' },
	{ key: 'north-korea', label: 'North Korea' },
	{ key: 'northen-cyprus', label: 'Northen Cyprus' },
	{ key: 'northern-marianas-islands', label: 'Northern Marianas Islands' },
	{ key: 'norway', label: 'Norway' },
	{ key: 'oman', label: 'Oman' },
	{ key: 'orkney-islands', label: 'Orkney Islands' },
	{ key: 'ossetia', label: 'Ossetia' },
	{ key: 'otan', label: 'Otan' },
	{ key: 'pakistan', label: 'Pakistan' },
	{ key: 'palau', label: 'Palau' },
	{ key: 'palestine', label: 'Palestine' },
	{ key: 'panama', label: 'Panama' },
	{ key: 'papua-new-guinea', label: 'Papua New Guinea' },
	{ key: 'paraguay', label: 'Paraguay' },
	{ key: 'peru', label: 'Peru' },
	{ key: 'philippines', label: 'Philippines' },
	{ key: 'pitcairn-islands', label: 'Pitcairn Islands' },
	{ key: 'poland', label: 'Poland' },
	{ key: 'portugal', label: 'Portugal' },
	{ key: 'puerto-rico', label: 'Puerto Rico' },
	{ key: 'qatar', label: 'Qatar' },
	{ key: 'rapa-nui', label: 'Rapa Nui' },
	{ key: 'republic-of-macedonia', label: 'Republic Of Macedonia' },
	{ key: 'republic-of-the-congo', label: 'Republic Of The Congo' },
	{ key: 'romania', label: 'Romania' },
	{ key: 'russia', label: 'Russia' },
	{ key: 'rwanda', label: 'Rwanda' },
	{ key: 'saba-island', label: 'Saba Island' },
	{ key: 'sahrawi-arab-democratic-republic', label: 'Sahrawi Arab Democratic Republic' },
	{ key: 'saint-kitts-and-nevis', label: 'Saint Kitts And Nevis' },
	{ key: 'samoa', label: 'Samoa' },
	{ key: 'san-marino', label: 'San Marino' },
	{ key: 'sao-tome-and-principe', label: 'Sao Tome And Principe' },
	{ key: 'sardinia', label: 'Sardinia' },
	{ key: 'saudi-arabia', label: 'Saudi Arabia' },
	{ key: 'scotland', label: 'Scotland' },
	{ key: 'senegal', label: 'Senegal' },
	{ key: 'serbia', label: 'Serbia' },
	{ key: 'seychelles', label: 'Seychelles' },
	{ key: 'sicily', label: 'Sicily' },
	{ key: 'sierra-leone', label: 'Sierra Leone' },
	{ key: 'singapore', label: 'Singapore' },
	{ key: 'sint-eustatius', label: 'Sint Eustatius' },
	{ key: 'sint-maarten', label: 'Sint Maarten' },
	{ key: 'slovakia', label: 'Slovakia' },
	{ key: 'slovenia', label: 'Slovenia' },
	{ key: 'solomon-islands', label: 'Solomon Islands' },
	{ key: 'somalia', label: 'Somalia' },
	{ key: 'somaliland', label: 'Somaliland' },
	{ key: 'south-africa', label: 'South Africa' },
	{ key: 'south-korea', label: 'South Korea' },
	{ key: 'south-sudan', label: 'South Sudan' },
	{ key: 'spain', label: 'Spain' },
	{ key: 'sri-lanka', label: 'Sri Lanka' },
	{ key: 'st-barts', label: 'St Barts' },
	{ key: 'st-lucia', label: 'St Lucia' },
	{ key: 'st-vincent-and-the-grenadines', label: 'St Vincent And The Grenadines' },
	{ key: 'sudan', label: 'Sudan' },
	{ key: 'suriname', label: 'Suriname' },
	{ key: 'sweden', label: 'Sweden' },
	{ key: 'switzerland', label: 'Switzerland' },
	{ key: 'syria', label: 'Syria' },
	{ key: 'taiwan', label: 'Taiwan' },
	{ key: 'tajikistan', label: 'Tajikistan' },
	{ key: 'tanzania', label: 'Tanzania' },
	{ key: 'thailand', label: 'Thailand' },
	{ key: 'tibet', label: 'Tibet' },
	{ key: 'togo', label: 'Togo' },
	{ key: 'tokelau', label: 'Tokelau' },
	{ key: 'tonga', label: 'Tonga' },
	{ key: 'transnistria', label: 'Transnistria' },
	{ key: 'trinidad-and-tobago', label: 'Trinidad And Tobago' },
	{ key: 'tubalu', label: 'Tubalu' },
	{ key: 'tunisia', label: 'Tunisia' },
	{ key: 'turkey', label: 'Turkey' },
	{ key: 'turkmenistan', label: 'Turkmenistan' },
	{ key: 'turks-and-caicos', label: 'Turks And Caicos' },
	{ key: 'uganda', label: 'Uganda' },
	{ key: 'ukraine', label: 'Ukraine' },
	{ key: 'united-arab-emirates', label: 'United Arab Emirates' },
	{ key: 'united-kingdom', label: 'United Kingdom' },
	{ key: 'united-nations', label: 'United Nations' },
	{ key: 'united-states-of-america', label: 'United States Of America' },
	{ key: 'uruguay', label: 'Uruguay' },
	{ key: 'uzbekistn', label: 'Uzbekistn' },
	{ key: 'vanuatu', label: 'Vanuatu' },
	{ key: 'vatican-city', label: 'Vatican City' },
	{ key: 'venezuela', label: 'Venezuela' },
	{ key: 'vietnam', label: 'Vietnam' },
	{ key: 'virgin-islands', label: 'Virgin Islands' },
	{ key: 'wales', label: 'Wales' },
	{ key: 'yemen', label: 'Yemen' },
	{ key: 'zambia', label: 'Zambia' },
	{ key: 'zimbabwe', label: 'Zimbabwe' }
];