import React from 'react';
import {
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";

export default props => {

  return (
    <Button
      color={props.color || "primary"}
      variant="contained"
      size="large"
      className="HeaderButton"
      component={props.navigate ? Link : "button"}
      startIcon={props.icon}
      {...props}
    >
      {props.children}
    </Button>
  );
}