import React from 'react';
import ReactDOM from 'react-dom';
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { ConfirmProvider } from "material-ui-confirm";
import { BrowserRouter as Router } from "react-router-dom";
import './index.css';
import App from './App';
import Apps from './TestScenes/Apps';

import Home from "./scenes/Home";

import * as serviceWorker from './serviceWorker';

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";

function Capitalize(str){
return str.charAt(0).toUpperCase() + str.slice(1);
}

const theme = createMuiTheme({
  shadows: ["none"],
  shape: {
    borderRadius: 10,
  },
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#FFBD2C",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#4B4B4B",
    },
    light: {
      // This is green.A700 as hex.
      main: "#EEEEEF",
    },
  },
  typography: {
    h2: {
      fontSize: "2.5rem",
      lineHeight: "3rem",
      fontWeight: 500,
      color: "#2A2827",
    },
    h3: {
      fontSize: "1.25rem",
      lineHeight: "1.5rem",
      fontWeight: 400,
      color: "#4B4B4B",
    },
    body1: {
      color: "#686E71",
    },
    button: {
      textTransform: "none",
    },
  },
  overrides: {
    MuiInput: {
      input: {
        "&::placeholder": {
          color: "#B4B4B7",
          opacity: 1,
        },
      },
    },
    MuiFormLabel: {
      label: {
        textTransform: "uppercase",
        color: "#686E71",
        fontSize: "0.916rem",
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ThemeProvider theme={theme}>
            <ConfirmProvider>
              <App />
            </ConfirmProvider>
          </ThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
