import React, { useState } from 'react';

import {
  InputLabel,
  FormHelperText,
  TextField,
  Button
} from "@material-ui/core";


export default props => {

  return (
    <React.Fragment>
      <div>
        <InputLabel className={["MuiInputLabel-shrink", props.classes.label]}>
          {props.definition.label}
        </InputLabel>
        <FormHelperText
          className={`${props.classes.helperText} ${
            props.error ? props.classes.error : null
          }`}
        >
          {props.error || props.definition.help}
        </FormHelperText>
      </div>


      {(props.value()||[]).map((e,k) => (
        <TextField
          fullWidth
          value={e}
          onChange={(e) => {
            const newVal = e.target.value;
            const globalVal = [ ...props.value() ];
            globalVal[k] = newVal;
            props.onChange({ target: { value: globalVal } })
          }}
        />
      ))}

      <Button onClick={() => props.onChange({target: { value: [ ...(props.value()||[]), '' ] }})}>Nuevo item</Button>

    </React.Fragment>
  );
};