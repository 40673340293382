import React, { Fragment, useState, useEffect } from "react";



import {
  CloudDownloadOutlined as DownloadIcon,
  CloudUploadOutlined as UploadIcon,
} from "@material-ui/icons";

import NavBar from "../../components/NavBar";
import PageHeader from "../../components/PageHeader";
import PageScreen from "../../components/PageScreen";
import HeaderButton from "../../components/HeaderButton";
import DataView from "../../components/DataView";
import RightModal from "../../components/RightModal/v2";

import { useConfirm } from "material-ui-confirm";

import { Link, useParams } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as audiencesActionCreators from "../../data/audiences/actions";

import Switch from "@material-ui/core/Switch";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import TextField from '@material-ui/core/TextField';


const BASE_QUERY = {
  $sort: { name: 1 }
};

const Audiences = (props) => {

  const MODAL_MANIFEST = [
    [
      {
        key: "name",
        type: "string",
        label: "Nombre",
      },
    ]
  ];
  const MODAL_LANG_MANIFEST = [
  ];

  useEffect(() => {
    props.actions.audiences.findItems({ query: BASE_QUERY });
  }, []);

  const [currentItem, setCurrentItem] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [query, setQuery] = useState("");

  const handleNew = () => {
    setCurrentItem({});
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    setCurrentItem(item);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setCurrentItem({});
  };


  const confirm = useConfirm();

  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
          { title: props.selectedApp.name, path: `/${props.selectedApp.slug}/` },
          { title: "Audiencias" },
        ]}
      />
      <PageScreen className="ListPage">
        <PageHeader
          title="Audiencias"
          renderActions={() => (
            <Fragment>
              <HeaderButton onClick={handleNew}>+ Nueva audiencia</HeaderButton>
            </Fragment>
          )}
        />
        <DataView
          items={props.audiences.items}
          total={props.audiences.total}
          loadPage={(page, perPage) => props.actions.audiences.findItems({ query: {
            ...BASE_QUERY,
            $skip: (perPage * page),
          } })}
          columnDefs={[
            { ref: "name", align: "left" },
            { ref: "userCount", align: "left" },
            { ref: "actions", align: "left" },
          ]}
          renderHeader={(ref) => {
            switch (ref) {
              case "name":
                return "Nombre";
              case "userCount":
                return "Num. Usuarios";
              case "actions":
                return "Acciones";
              default:
                return null;
            }
          }}
          renderCell={(ref, item) => {
            switch (ref) {
              case "name":
                return item[ref];
              case "userCount":
                return item[ref];
              case "actions":
                return (
                  <Fragment>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      className="EditButton"
                      onClick={() => handleEdit(item)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      className="DeleteButton"
                      onClick={() => {
                        confirm({
                          title: "¿Eliminar audiencia?",
                          description: `Esta acción eliminará por completo la
                          audiencia.
                          Esta acción es permenente y no tiene marcha atrás.`,
                          confirmationText: "Eliminar",
                          confirmationButtonProps: { variant: "contained" },
                          cancellationText: "Cancelar",
                          cancellationButtonProps: { variant: "contained" },
                        })
                          .then(() => {
                            props.actions.audiences.remove(item);
                          })
                          .catch(() => {});
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Fragment>
                );
              default:
                return null;
            }
          }}
          empty={{
            title: "Añade tu primera audiencia",
            body:
              "Añade audiencias a tu app y organiza los usuarios con ellas.",
            cta: "+ Crear nueva audiencia",
            action: handleNew,
          }}
        />
      </PageScreen>
      <RightModal
        newTitle="Crear audiencia"
        editTitle="Editar audiencia"
        open={modalOpen}
        currentItem={currentItem}
        handleClose={handleClose}
        createAction={props.actions.audiences.create}
        patchAction={props.actions.audiences.patch}
        manifest={MODAL_MANIFEST}
        langManifest={MODAL_LANG_MANIFEST}
        actions={props.actions.audiences}
      />
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    audiences: state.data.audiences,
  }),
  (dispatch) => ({
    actions: {
      audiences: bindActionCreators(audiencesActionCreators, dispatch),
    },
  })
)(Audiences);
