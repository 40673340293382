import React, {} from 'react';

import {
  TextField,
} from "@material-ui/core";


export default props => {
  return (
    <TextField
      fullWidth
      label={`${props.definition.label}${props.definition.multilang ? ` (${props.currentLang})` : ''}`}
      InputLabelProps={{
        shrink: true,
        className: props.classes.label,
      }}
      placeholder={props.definition.label}
      helperText={props.error || props.definition.help}
      FormHelperTextProps={{
        className: `${props.classes.helperText} ${
          props.error ? props.classes.error : null
        }`,
      }}
      value={props.value()}
      onChange={props.onChange}
    />
  );
};