import React, { useState, useEffect, Fragment } from "react";

import FontIconPicker from "@fonticonpicker/react-fonticonpicker";

import {
  TextField,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import "@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css";
import "@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css";




const useStyles = makeStyles((theme) => ({
  outerBox: {
    position: "relative",
  },
  iconBox: {
    position: "absolute",
    top: "100%",
    left: 0,
    backgroundColor: "#fff",
    zIndex: 1200,
    border: "1px solid #888",
    display: "flex",
    flexDirection: "row",
    width: '100%',
    flexWrap: "wrap",
    padding: "0.5rem",
  },
  icon: {
    width: 18,
    padding: "4px 0",
    textAlign: "center",
    boxSizing: "border-box",
    cursor: "pointer",
    color: '#777',
    "&:hover": {
      color: '#000'
    },
  },
}));

export default props => {

  const classes = useStyles();

  const [open, setOpen] = useState(false);



  return (
    <React.Fragment>
      <div>
        <InputLabel className={["MuiInputLabel-shrink", props.classes.label]}>
          {props.definition.label}
        </InputLabel>
        <FormHelperText
          className={`${props.classes.helperText} ${
            props.error ? props.classes.error : null
          }`}
        >
          {props.error || props.definition.help}
        </FormHelperText>
      </div>
      <FontIconPicker
        icons={[
          "icon-md-question",
          "icon-md-star",
          "icon-sm-send",
          "icon-md-adjustments",
          "icon-md-annotation",
          "icon-md-archive",
          "icon-md-arrow-circle-down",
          "icon-md-arrow-circle-left",
          "icon-md-arrow-circle-right",
          "icon-md-arrow-circle-up",
          "icon-md-arrow-down",
          "icon-md-arrow-left",
          "icon-md-arrow-narrow-down",
          "icon-md-arrow-narrow-left",
          "icon-md-arrow-narrow-right",
          "icon-md-arrow-narrow-up",
          "icon-md-arrow-right",
          "icon-md-arrow-up",
          "icon-md-at-symbol",
          "icon-md-bell",
          "icon-md-book-open",
          "icon-md-bookmark",
          "icon-md-briefcase",
          "icon-md-calendar",
          "icon-md-camera",
          "icon-md-cash",
          "icon-md-chart-pie",
          "icon-md-chat",
          "icon-md-check-circle",
          "icon-md-check",
          "icon-md-cheveron-down",
          "icon-md-cheveron-left",
          "icon-md-cheveron-right",
          "icon-md-cheveron-up",
          "icon-md-clipboard-check",
          "icon-md-clipboard-copy",
          "icon-md-clipboard-list",
          "icon-md-clipboard",
          "icon-md-clock",
          "icon-md-cloud-download",
          "icon-md-cloud-upload",
          "icon-md-code",
          "icon-md-cog",
          "icon-md-collection",
          "icon-md-color-swatch",
          "icon-md-credit-card",
          "icon-md-currency-dollar",
          "icon-md-currency-euro",
          "icon-md-currency-pound",
          "icon-md-currency-rupee",
          "icon-md-currency-yen",
          "icon-md-document-add",
          "icon-md-document-download",
          "icon-md-document-duplicate",
          "icon-md-document-remove",
          "icon-md-document",
          "icon-md-dots-circle-horizontal",
          "icon-md-dots-horizontal",
          "icon-md-dots-vertical",
          "icon-md-download",
          "icon-md-duplicate",
          "icon-md-emoji-happy",
          "icon-md-emoji-sad",
          "icon-md-exclamation-circle",
          "icon-md-exclamation",
          "icon-md-external-link",
          "icon-md-eye",
          "icon-md-filter",
          "icon-md-flag",
          "icon-md-folder",
          "icon-md-globe-alt",
          "icon-md-globe",
          "icon-md-hashtag",
          "icon-md-heart",
          "icon-md-home",
          "icon-md-inbox-in",
          "icon-md-inbox",
          "icon-md-information-circle",
          "icon-md-light-bulb",
          "icon-md-lightning-bolt",
          "icon-md-link",
          "icon-md-location-marker",
          "icon-md-lock-closed",
          "icon-md-lock-open",
          "icon-md-mail-open",
          "icon-md-mail",
          "icon-md-menu-alt-1",
          "icon-md-menu-alt-2",
          "icon-md-menu-alt-3",
          "icon-md-menu-alt-4",
          "icon-md-menu",
          "icon-md-minus-circle",
          "icon-md-moon",
          "icon-md-office-building",
          "icon-md-paper-clip",
          "icon-md-pencil-alt",
          "icon-md-pencil",
          "icon-md-phone-incoming",
          "icon-md-phone-outgoing",
          "icon-md-phone",
          "icon-md-photograph",
          "icon-md-plus-circle",
          "icon-md-plus",
          "icon-md-printer",
          "icon-md-qrcode",
          "icon-md-question-mark-circle",
          "icon-md-receipt-refund",
          "icon-md-refresh",
          "icon-md-reply",
          "icon-md-scale",
          "icon-md-search",
          "icon-md-selector",
          "icon-md-share",
          "icon-md-shield-check",
          "icon-md-shield-exclamation",
          "icon-md-sort-ascending",
          "icon-md-sort-descending",
          "icon-md-sparkles",
          "icon-md-sun",
          "icon-md-switch-horizontal",
          "icon-md-switch-vertical",
          "icon-md-tag",
          "icon-md-template",
          "icon-md-ticket",
          "icon-md-translate",
          "icon-md-trash",
          "icon-md-trending-down",
          "icon-md-trending-up",
          "icon-md-upload",
          "icon-md-user-add",
          "icon-md-user-circle",
          "icon-md-user-group",
          "icon-md-user-remove",
          "icon-md-user",
          "icon-md-users",
          "icon-md-view-boards",
          "icon-md-view-list",
          "icon-md-volume-off",
          "icon-md-volume-up",
          "icon-md-x-circle",
          "icon-md-x",
          "icon-md-zoom-in",
          "icon-md-zoom-out",
          "icon-sm-adjustments",
          "icon-sm-annotation",
          "icon-sm-archive",
          "icon-sm-arrow-circle-down",
          "icon-sm-arrow-circle-left",
          "icon-sm-arrow-circle-right",
          "icon-sm-arrow-circle-up",
          "icon-sm-arrow-down",
          "icon-sm-arrow-left",
          "icon-sm-arrow-narrow-down",
          "icon-sm-arrow-narrow-left",
          "icon-sm-arrow-narrow-right",
          "icon-sm-arrow-narrow-up",
          "icon-sm-arrow-right",
          "icon-sm-arrow-up",
          "icon-sm-at-symbol",
          "icon-sm-bell",
          "icon-sm-book-open",
          "icon-sm-bookmark",
          "icon-sm-briefcase",
          "icon-sm-calendar",
          "icon-sm-camera",
          "icon-sm-cash",
          "icon-sm-chart-pie",
          "icon-sm-chat",
          "icon-sm-check-circle",
          "icon-sm-check",
          "icon-sm-cheveron-down",
          "icon-sm-cheveron-left",
          "icon-sm-cheveron-right",
          "icon-sm-cheveron-up",
          "icon-sm-clipboard-check",
          "icon-sm-clipboard-copy",
          "icon-sm-clipboard-list",
          "icon-sm-clipboard",
          "icon-sm-clock",
          "icon-sm-cloud-download",
          "icon-sm-cloud-upload",
          "icon-sm-code",
          "icon-sm-cog",
          "icon-sm-collection",
          "icon-sm-color-swatch",
          "icon-sm-credit-card",
          "icon-sm-currency-dollar",
          "icon-sm-currency-euro",
          "icon-sm-currency-pound",
          "icon-sm-currency-rupee",
          "icon-sm-currency-yen",
          "icon-sm-document-add",
          "icon-sm-document-download",
          "icon-sm-document-duplicate",
          "icon-sm-document-remove",
          "icon-sm-document",
          "icon-sm-dots-circle-horizontal",
          "icon-sm-dots-horizontal",
          "icon-sm-dots-vertical",
          "icon-sm-download",
          "icon-sm-duplicate",
          "icon-sm-emoji-happy",
          "icon-sm-emoji-sad",
          "icon-sm-exclamation-circle",
          "icon-sm-exclamation",
          "icon-sm-external-link",
          "icon-sm-eye",
          "icon-sm-filter",
          "icon-sm-flag",
          "icon-sm-folder",
          "icon-sm-globe-alt",
          "icon-sm-globe",
          "icon-sm-hashtag",
          "icon-sm-heart",
          "icon-sm-home",
          "icon-sm-inbox-in",
          "icon-sm-inbox",
          "icon-sm-information-circle",
          "icon-sm-light-bulb",
          "icon-sm-lightning-bolt",
          "icon-sm-link",
          "icon-sm-location-marker",
          "icon-sm-lock-closed",
          "icon-sm-lock-open",
          "icon-sm-mail-open",
          "icon-sm-mail",
          "icon-sm-menu-alt-1",
          "icon-sm-menu-alt-2",
          "icon-sm-menu-alt-3",
          "icon-sm-menu-alt-4",
          "icon-sm-menu",
          "icon-sm-minus-circle",
          "icon-sm-moon",
          "icon-sm-office-building",
          "icon-sm-paper-clip",
          "icon-sm-pencil-alt",
          "icon-sm-pencil",
          "icon-sm-phone-incoming",
          "icon-sm-phone-outgoing",
          "icon-sm-phone",
          "icon-sm-photograph",
          "icon-sm-plus-circle",
          "icon-sm-plus",
          "icon-sm-printer",
          "icon-sm-qrcode",
          "icon-sm-question-mark-circle",
          "icon-sm-receipt-refund",
          "icon-sm-refresh",
          "icon-sm-reply",
          "icon-sm-scale",
          "icon-sm-search",
          "icon-sm-selector",
          "icon-sm-share",
          "icon-sm-shield-check",
          "icon-sm-shield-exclamation",
          "icon-sm-sort-ascending",
          "icon-sm-sort-descending",
          "icon-sm-sparkles",
          "icon-sm-sun",
          "icon-sm-switch-horizontal",
          "icon-sm-switch-vertical",
          "icon-sm-tag",
          "icon-sm-template",
          "icon-sm-ticket",
          "icon-sm-translate",
          "icon-sm-trash",
          "icon-sm-trending-down",
          "icon-sm-trending-up",
          "icon-sm-upload",
          "icon-sm-user-add",
          "icon-sm-user-circle",
          "icon-sm-user-group",
          "icon-sm-user-remove",
          "icon-sm-user",
          "icon-sm-users",
          "icon-sm-view-boards",
          "icon-sm-view-list",
          "icon-sm-volume-off",
          "icon-sm-volume-up",
          "icon-sm-x-circle",
          "icon-sm-x",
          "icon-sm-zoom-in",
          "icon-sm-zoom-out",
        ]}
        closeOnSelect={true}
        value={(props.value() && props.value().indexOf('icon-sm') == -1 && props.value().indexOf('icon-md') == -1) ? `icon-md-${props.value()}` : props.value()}
        onChange={(val) => props.onChange({ target: { value: val } })}
      />
    </React.Fragment>
  );

}
