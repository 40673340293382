import * as api from "./api";
import * as actionTypes from "./actionTypes";

const update = (items, total) => ({
  type: actionTypes.UPDATE,
  items,
  total
});

export const append = (items, total) => ({
  type: actionTypes.APPEND,
  items,
  total
});

export const prepend = (item) => ({
  type: actionTypes.PREPEND,
  item
});

export const updateOne = (item) => ({
  type: actionTypes.UPDATE_ONE,
  item,
});

export const destroy = (item) => ({
  type: actionTypes.DESTROY,
  item,
});

const loading = () => ({
  type: actionTypes.LOADING
});

export const patch = (item, payload) => (dispatch) =>
         api.patch(item._id, payload).then((r) => dispatch(updateOne(r)));

export const remove = (item) => (dispatch) =>
         api.remove(item._id).then((r) => dispatch(destroy(r)));

export const findItems = params => dispatch => {
  dispatch(loading());
  return api
    .find(params)
    .then((r) => dispatch(update(r.data, r.total)))
    .catch((err) => {
      console.log('Error', err);
      throw(err);
    });
}

export const create = payload => dispatch => {
  return api
    .create(payload)
    .then((r) => dispatch(append(r)))
    .catch((err) => {
      console.log("Error", err);
      throw err;
    });
}