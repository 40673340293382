import React, {} from 'react';

import {
  TextField,
} from "@material-ui/core";

import {
  Autocomplete,
} from "@material-ui/lab";


export default props => {
  return (
    <Autocomplete
      id="free-solo-demo"
      autoSelect
      options={props.definition.options}
      getOptionLabel={o => o.label}
      onChange={(e,v) => props.onChange(v.key)}
      value={props.value()}
      renderInput={(params) => (
        <TextField
          margin="normal"
          {...params}
          fullWidth
          label={`${props.definition.label}${props.definition.multilang ? ` (${props.currentLang})` : ''}`}
          InputLabelProps={{
            shrink: true,
            className: props.classes.label,
          }}
          placeholder={props.definition.label}
          helperText={props.error || props.definition.help}
          FormHelperTextProps={{
            className: `${props.classes.helperText} ${
              props.error ? props.classes.error : null
            }`,
          }}
        />
      )}
    />
  );
};