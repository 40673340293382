import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';

// OWN ROOT COMPONENTS

// WRAPPERS AND CONTEXT CREATORS
import FeathersHelper from '../../helpers/feathers';

// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";

// OTHER UTILS
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';

const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const useStyles = makeStyles(theme => ({
  outerContainer: {
    backgroundColor: '#EAEBF0',
    height: '100vh',
    display: 'flex',
  },
  container: {
    margin: 30,
    borderRadius: 10,
    flex: 1,
    backgroundColor: '#ffffff',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '40px 127px',
    [theme.breakpoints.down('md')]: {
      padding: 40,
      margin: 0,
      borderRadius: 0
    }
  },
  header: {
    marginTop: sizingIndex => sizingIndex * 40,
    color: '#4B4B4B',
    fontSize: sizingIndex => sizingIndex * 48,
    lineHeight: 1.16,
    textAlign: 'center',
    fontWeight: '500',
    fontFamily: 'MatterWeb'
  },
  chart: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: sizingIndex => sizingIndex * 50,
    marginBottom: 0,
    overflow: 'hidden'
  },
  innerChart: {

  },
  option: {
    marginTop: sizingIndex => sizingIndex * 18,
    flexDirection: 'column'
  },
  labels: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  textLabel: {
    color: '#444444',
    fontSize: sizingIndex => sizingIndex * 24,
    lineHeight: 1.5,
    flex: 1,
    paddingRight: sizingIndex => sizingIndex * 24,
    fontFamily: 'MatterWeb'
  },
  percentLabel: {
    color: '#444444',
    fontSize: sizingIndex => sizingIndex * 24,
    fontWeight: '500',
    fontFamily: 'MatterWeb'
  },
  bar: {
    marginTop: sizingIndex => sizingIndex * 20,
    height: sizingIndex => sizingIndex * 48,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch'
  },
  barFill: {
    borderRadius: 3,
    width: '0',
    margin: 2
  }
}));

const MAX_REFRESH = 1000;
const REFRESH_TRESHOLD = 5000;

const Component = (props) => {

  const [outerSize, setOuterSize] = useState(0);
  const [innerSize, setInnerSize] = useState(0);
  const [sizingIndex, setSizingIndex] = useState(0);
  const [projection, setProjection] = useState(null);

  const lastUpdate = useRef(0);
  const pendingUpdate = useRef(false);

  const classes = useStyles(sizingIndex);
  const { moduleId } = useParams();

  const { themeColor } = props.data.selectedApp;
  const components = hexToRgb(themeColor);

  const locale = props.data.selectedApp.langPattern ? props.data.selectedApp.langPattern.default : '_';

  const chart = useRef();
  const innerChart = useRef();

  useEffect(() => {
    setSizingIndex(1);
  }, [projection]);

  useEffect(() => {
    if (chart.current && innerChart.current) {
      setOuterSize(chart.current.getBoundingClientRect().height);
      setInnerSize(innerChart.current.getBoundingClientRect().height);
      const _outerSize = chart.current.getBoundingClientRect().height;
      const _innerSize = innerChart.current.getBoundingClientRect().height;
      if (_outerSize < _innerSize) {
        console.log(`Outer is ${_outerSize} inner is ${_innerSize}, decided to resize`);
        setSizingIndex( si => si * 0.9 );
      } else {
        console.log("Its good already")
      }
    }
  }, [sizingIndex, projection]);

  const receivedRefresh = (r = null) => {
    if (!r || r.data._id == moduleId) {
      console.log(Date.now());
      console.log(lastUpdate.current);
      if (Date.now() - lastUpdate.current > MAX_REFRESH) { // ONLY REFRESH IF 5SEC PASSED
        FeathersHelper.get('projections', moduleId)
          .then( async p => {
            setProjection(p);
            lastUpdate.current = Date.now();
            pendingUpdate.current = false;
          });
      } else {
        pendingUpdate.current = true;
      }

    }
  }

  useEffect(() => {

    receivedRefresh();

    FeathersHelper.app.service('projections').on('updated', receivedRefresh);

    // We watch for pending refreshes
    const interval = setInterval(() => {
      if (pendingUpdate.current && ( Date.now() - lastUpdate.current > MAX_REFRESH ) ) receivedRefresh();
    }, REFRESH_TRESHOLD);

    return () => {
      FeathersHelper.app.service('projections').off('updated', receivedRefresh);
      clearInterval(interval);
    };
  }, [])



  return projection ? (
    <div className={classes.outerContainer}>
      <div className={classes.container}>
        <div className={classes.header}>{projection.title[locale]}</div>
        <div className={classes.chart} ref={chart}>
          <div className={classes.innerChart} ref={innerChart}>
            {projection.options.map( o => (
              <div key={o._id} className={classes.option}>
                <div className={classes.labels}>
                  <div className={classes.textLabel}>{o.title[locale]}</div>
                  <div className={classes.percentLabel} style={{ color: themeColor }}>{Math.round(o.percent * 100)}%</div>
                </div>
                <div className={classes.bar} style={{ backgroundColor: components ? `rgba(${components.r},${components.g},${components.b},0.05)` : '#000000' }}>
                  <div className={classes.barFill} style={{ backgroundColor: themeColor, width: `${Math.round(o.percent * 100)}%` }}></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default connect(
  (state) => ({
    data: {
      selectedApp: state.data.apps.selected,
    },
  }),
  null
)(Component);