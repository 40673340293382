import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useParams, useRoutes } from "react-router-dom";

import routes from './routes';

import { AppBar, Toolbar, Container, Box, Breadcrumbs, Link, Typography } from "@material-ui/core";
import { Menu as MenuIcon, NavigateNext as NavigateNextIcon } from "@material-ui/icons";

import NavBar from './components/NavBar';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appsActionCreators from "./data/apps/actions";

import 'react-quill/dist/quill.snow.css';
import './App.scss';


import AppLoader from "./components/AppLoader";






const App = props => {

  const [slugReady, setSlugReady] = useState(false);

  useEffect(() => {
    const slug = window.location.pathname.split("/").filter((p) => p !== "")[0];
    if (props.apps.selected.slug !== slug) {
      console.log("processparams should get this", slug);
      if(props.app.feathersReady) {
        props.actions.apps.setSelectedFromSlug(slug);
      }
    }
    setSlugReady(true);
  }, [props.app.feathersReady]);

  console.log("THE APP", props.app);

  const isLoggedIn = props.app.feathersAuthenticated;

  const routing = useRoutes(routes(isLoggedIn, props.apps.selected));

  return (
    <div className="App">
        {props.app.feathersConnected && slugReady && props.app.feathersReady ? routing : <AppLoader /> }
        {/*<Routes>
          <Route path="/" element={<Home />} />
          <Route path="/new" element={<Wizard />} />
          <Route path={`/:slug`} element={<Modules />} />
          <Route path={`/:slug/settings`} element={<Settings />} />
          <Route
            path="/:slug/module/:moduleId/appointments"
            element={<Appointments />}
          />
          <Route
            path="/:slug/module/:moduleId/static-contents"
            element={<StaticContents />}
          />
          <Route path="/:slug/module/:moduleId/blocks" element={<Modules />} />
          <Route path="/:slug/virtual-rooms" element={<VirtualRooms />} />
          <Route path="/:slug/users" element={<Users />} />
          <Route path="/:slug/users/import" element={<UsersImport />} />
          <Route path="/:slug/notifications" element={<Notifications />} />
          <Route path="/:slug/reports" element={<Reports />} />
        </Routes>*/}

      {props.apps.loading && <AppLoader />}
    </div>
  );
}

export default connect(
  (state, ownProps) => ({
    app: state.services.app,
    apps: state.data.apps,
  }),
  (dispatch) => ({
    actions: {
      apps: bindActionCreators(appsActionCreators, dispatch),
    },
  })
)(App);