import React, { Fragment, useState } from "react";

import { connect } from "react-redux";

import {
} from "@material-ui/core/";


import NavBar from "../../components/NavBar";
import PageScreen from "../../components/PageScreen";


const Analytics = (props) => {

  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
          { title: props.selectedApp.name, path: `/${props.selectedApp.slug}` },
          { title: "Analíticas" },
        ]}
      />
      <iframe src={props.selectedApp.analyticsUrl} style={{ flex: 1, border: 'none' }} />
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
  }),
  null
)(Analytics);
