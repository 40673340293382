import React, { Fragment, useState, useEffect } from "react";

import { Grid, Paper, Box, Typography, Button, Table, TableHead, TableBody, TableCell, TableRow, TableContainer, TextField } from "@material-ui/core/";
import { Delete as DeleteIcon, Edit as EditIcon, Clear } from "@material-ui/icons/";

import NavBar from "../../components/NavBar";
import PageHeader from "../../components/PageHeader";
import PageScreen from "../../components/PageScreen";
import Form from "../../components/Form";
import FormField from "../../components/FormField";
import Field from '../../components/RightModal/components/Field';

import ReactCountryFlag from "react-country-flag";

import { ChevronLeft, AccountBalance } from "@material-ui/icons";

import { scaledImageSource } from "../../components/ScaledImage";

import { useNavigate } from "react-router-dom";

import FeathersHelper from '../../helpers/feathers';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appsActionCreators from "../../data/apps/actions";
import * as appActionCreators from "../../services/app/actions";

import MDEditor from '@uiw/react-md-editor';

import defaults from '../../defaults.json';

const Settings = (props) => {

  const [admins, setAdmins] = useState([]);
  const [adminMail, setAdminMail] = useState('');

  const loadAdmins = () => {
    FeathersHelper.app.service("admins").find({ query: { apps: props.selectedApp._id, $limit: -1 } }).then( r => {
      setAdmins(r);
    });
  };

  useEffect(() => {
    loadAdmins();
  }, [props.selectedApp]);

  const termsForApp = (locales) => {
    const terms = {};
    locales.map( l => {
      terms[l] = defaults.terms[l]||defaults.terms.default;
    })
    return terms;
  }

  const addAdmin = () => {
    if( adminMail != '') {
      FeathersHelper.app.service("apps").patch(props.selectedApp._id, { newAdmin: adminMail }).then(r => {
        setAdminMail('');
        loadAdmins();
      }).catch((e) => alert("Email no encontrado"));
    }
  };


  const renderFields = (
    newObject,
    valueForPath,
    setValueForPath,
    erroredFields,
    handleSave,
    currentLang,
    setCurrentLang
  ) => (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <Box m={2}>
          <Paper>
            <Box p={3}>
              <Typography component="h3" variant="h3">
                Información de la app
              </Typography>
              <Box mt={3}>
                <FormField
                  id={`field_name`}
                  error={erroredFields("name")}
                  definition={{
                    key: "name",
                    type: "string",
                    label: "Nombre de la app",
                    help: "Nombre interno para identificar la app",
                  }}
                  size={12}
                  value={valueForPath("name")}
                  onChange={(e) => {
                    setValueForPath("name", e.target.value);
                  }}
                />
              </Box>
              <Box mt={3}>
                <FormField
                  id={`field_language`}
                  error={erroredFields("langPattern.available")}
                  definition={{
                    key: "name",
                    type: "transferList",
                    options: defaults.availableLanguages.map((al) => ({
                      key: al.code,
                      label: al.name,
                    })),
                    label: "Idiomas disponibles",
                  }}
                  size={12}
                  value={valueForPath("langPattern.available")}
                  onChange={(e) => {
                    setValueForPath("langPattern.available", e.target.value);
                  }}
                />
              </Box>
              <Box mt={3}>
                <FormField
                  id={`field_language`}
                  error={erroredFields("langPattern.default")}
                  definition={{
                    key: "name",
                    type: "select",
                    options: defaults.availableLanguages.filter((al) => (valueForPath("langPattern.available")||[]).indexOf(al.code) != -1).map((al) => ({
                      key: al.code,
                      label: al.name,
                    })),
                    label: "Idioma por defecto",
                  }}
                  size={12}
                  value={valueForPath("langPattern.default")}
                  onChange={(e) => {
                    setValueForPath("langPattern.default", e.target.value);
                  }}
                />
              </Box>
            </Box>
          </Paper>
        </Box>

        <Box m={2}>
          <Paper className="Language">
            <Box p={3}>
              <Typography component="h3" variant="h3">
                Textos
              </Typography>
              <div className="Picker">
                <Box px={4} pt={2}>
                  {defaults.availableLanguages.filter(l => valueForPath("langPattern.available")?.includes(l.code)).map((lang) => (
                    <button
                      type="button"
                      className={`FlagButton ${
                        currentLang === lang.code ? "active" : null
                      }`}
                      onClick={() => setCurrentLang(lang.code)}
                    >
                      <ReactCountryFlag
                        countryCode={lang.flag}
                        className="Icon"
                        svg
                      />
                    </button>
                  ))}
                </Box>
              </div>
              <Box mt={3}>
                <Field
                  id={`field_title`}
                  definition={{
                    key: "title",
                    multilang: true,
                    type: "string",
                    label: "Título de la app",
                    help: "Título que se mostrará en la cabecera de la App/VV",
                  }}
                  size={12}
                  basePath={[]}
                  currentLang={currentLang}
                  erroredFields={erroredFields}
                  valueForPath={valueForPath}
                  setValueForPath={setValueForPath}
                />
              </Box>
              <Box mt={3}>
                <Field
                  id={`field_terms`}
                  definition={{
                    key: "terms",
                    multilang: true,
                    type: "wysiwyg",
                    label: "Términos y condiciones",
                    help: "Texto legal que deberán aceptar los usuarios autenticados",
                  }}
                  size={12}
                  basePath={[]}
                  currentLang={currentLang}
                  erroredFields={erroredFields}
                  valueForPath={valueForPath}
                  setValueForPath={setValueForPath}
                />
                <Box mt={1}>
                  <Button variant="contained" color="primary" onClick={() => setValueForPath('terms', termsForApp(valueForPath("langPattern.available")))}>Aplicar textos por defecto</Button>
                </Box>

              </Box>

              <Box mt={3}>
                <FormField
                  id={`field_infoEnabled`}
                  error={erroredFields("infoEnabled")}
                  definition={{
                    key: "infoEnabled",
                    type: "switch",
                    label: "Info habilitada",
                    help:
                      "Decide si quieres que se muestre elemento de información del menú",
                  }}
                  size={12}
                  value={valueForPath("infoEnabled")}
                  onChange={(e) => {
                    setValueForPath("infoEnabled", e.target.value);
                  }}
                />
              </Box>
              <Box mt={3}>
                <Field
                  id={`field_infoTitle`}
                  definition={{
                    key: "infoTitle",
                    multilang: true,
                    type: "string",
                    label: "Título de la info",
                    help: "Título que se mostrará en el elemento de información del menú",
                  }}
                  size={12}
                  basePath={[]}
                  currentLang={currentLang}
                  erroredFields={erroredFields}
                  valueForPath={valueForPath}
                  setValueForPath={setValueForPath}
                />
              </Box>
              <Box mt={3}>
                <Field
                  id={`field_infoContent`}
                  definition={{
                    key: "infoContent",
                    multilang: true,
                    type: "wysiwyg",
                    label: "Contenido de la info",
                    help: "Texto que se mostrará dentro del elemento de información del menú",
                  }}
                  size={12}
                  basePath={[]}
                  currentLang={currentLang}
                  erroredFields={erroredFields}
                  valueForPath={valueForPath}
                  setValueForPath={setValueForPath}
                />
              </Box>

            </Box>
          </Paper>
        </Box>

        <Box m={2}>
          <Paper>
            <Box p={3}>
              <Typography component="h3" variant="h3">
                Branding
              </Typography>
              <Box mt={3}>
                <FormField
                  id={`field_color`}
                  error={erroredFields("color")}
                  definition={{
                    key: "color",
                    type: "color",
                    label: "Color principal",
                    codeLabel: "Código hex",
                    help: "Color que determinará la paleta de la app.",
                  }}
                  size={12}
                  value={valueForPath("themeColor")}
                  onChange={(e) => {
                    setValueForPath("themeColor", e.target.value);
                  }}
                />
              </Box>
              <Box mt={3}>
                <FormField
                  id={`field_banner`}
                  error={erroredFields("banner")}
                  definition={{
                    key: "banner",
                    type: "image",
                    aspectRatio: 1.9,
                    borderRadius: 10,
                    width: 1200,
                    height: 640,
                    label: "Banner de la app",
                    help: "Banner que se mostrará en la home de la app.",
                  }}
                  size={12}
                  value={valueForPath("banner")}
                  onChange={(e) => {
                    setValueForPath("banner", e.target.value);
                  }}
                />
              </Box>
              <Box mt={3} style={{ display: "flex", flexDirection: "row" }}>
                <Grid container spacing={2}>
                  <FormField
                    id={`field_icon`}
                    error={erroredFields("icon")}
                    definition={{
                      key: "icon",
                      type: "image",
                      aspectRatio: 1,
                      borderRadius: 10,
                      width: 512,
                      height: 512,
                      label: "Icono de la app",
                      help: "Icono de la app.",
                    }}
                    size={3}
                    value={valueForPath("icon")}
                    onChange={(e) => {
                      setValueForPath("icon", e.target.value);
                    }}
                  />
                  <FormField
                    id={`field_logo`}
                    error={erroredFields("logo")}
                    definition={{
                      key: "logo",
                      type: "image",
                      aspectRatio: 3.5,
                      borderRadius: 10,
                      width: 800,
                      height: 256,
                      label: "Logo de la app",
                      help: "Logo que se mostrará en la app.",
                    }}
                    size={9}
                    value={valueForPath("logo")}
                    onChange={(e) => {
                      setValueForPath("logo", e.target.value);
                    }}
                  />
                </Grid>
              </Box>
            </Box>
          </Paper>
        </Box>
        <Box m={2}>
          <Paper>
            <Box p={3}>
              <Typography component="h3" variant="h3">
                Control de acceso
              </Typography>
              <Box mt={3}>
                <FormField
                  id={`field_privateApp`}
                  error={erroredFields("accessOptions.private")}
                  definition={{
                    key: "privateApp",
                    type: "switch",
                    label: "App privada",
                    help:
                      "Decide si quieres que se requiera usuario/contraseña para acceder",
                  }}
                  size={12}
                  value={valueForPath("accessOptions.private")}
                  onChange={(e) => {
                    setValueForPath("accessOptions.private", e.target.value);
                  }}
                />
              </Box>
              <Box mt={3}>
                <FormField
                  id={`field_registerEnabled`}
                  error={erroredFields("accessOptions.registerable")}
                  definition={{
                    key: "registerEnabled",
                    type: "switch",
                    label: "Registro habilitado",
                    help:
                      "Decide si quieres que los usuarios se puedan registrar",
                  }}
                  size={12}
                  value={valueForPath("accessOptions.registerable")}
                  onChange={(e) => {
                    setValueForPath(
                      "accessOptions.registerable",
                      e.target.value
                    );
                  }}
                />
              </Box>
              <Box mt={3}>
                <FormField
                  id={`field_loginEnabled`}
                  error={erroredFields("accessOptions.loginable")}
                  definition={{
                    key: "loginEnabled",
                    type: "switch",
                    label: "Login habilitado",
                    help:
                      "Decide si quieres que se los usuarios puedan usar email/contraseña para acceder",
                  }}
                  size={12}
                  value={valueForPath("accessOptions.loginable")}
                  onChange={(e) => {
                    setValueForPath("accessOptions.loginable", e.target.value);
                  }}
                />
              </Box>
            </Box>
          </Paper>
        </Box>
        <Box m={2} style={{ textAlign: "right" }}>
          <Button
            color="primary"
            size="large"
            onClick={handleSave}
            variant="contained"
          >
            Guardar
          </Button>
        </Box>
      </Grid>
      <Grid item md={6}>
        <Box m={2}>
          <Paper>
            <Box p={3}>
              <Typography component="h3" variant="h3">
                Plugins
              </Typography>
              {/*<Box mt={3}>
                <FormField
                  id={`field_fastpassEnabled`}
                  error={erroredFields("plugins.fastPass.active")}
                  definition={{
                    key: "fastpassEnabled",
                    type: "switch",
                    label: "FastPass habilitado",
                    help:
                      "Activa el control de asistencia por FastPass",
                  }}
                  size={12}
                  value={valueForPath("plugins.fastPass.active")}
                  onChange={(e) => {
                    setValueForPath(
                      "plugins.fastPass.active",
                      e.target.value
                    );
                  }}
                />
                </Box>*/}
              <Box mt={3}>
                <FormField
                  id={`field_virtualVenuesEnabled`}
                  error={erroredFields("plugins.virtualVenues.active")}
                  definition={{
                    key: "virtualVenuesEnabled",
                    type: "switch",
                    label: "Virtual Venues habilitado",
                    help:
                      "Activa la plataforma de eventos virtuales",
                  }}
                  size={12}
                  value={valueForPath("plugins.virtualVenues.active")}
                  onChange={(e) => {
                    setValueForPath(
                      "plugins.virtualVenues.active",
                      e.target.value
                    );
                  }}
                />
              </Box>
            </Box>
          </Paper>
        </Box>
        <Box m={2}>
          <Paper>
            <Box p={3}>
              <Typography component="h3" variant="h3">
                Opciones de la home (app móvil)
              </Typography>
              <Box mt={3}>
                <FormField
                  id={`field_showAgenda`}
                  error={erroredFields("mobileHomeOptions.showAgenda")}
                  definition={{
                    key: "showAgenda",
                    type: "switch",
                    label: "Mostrar agenda",
                    help:
                      "Mostrar resumen de agenda del día",
                  }}
                  size={12}
                  value={valueForPath("mobileHomeOptions.showAgenda")}
                  onChange={(e) => {
                    setValueForPath("mobileHomeOptions.showAgenda", e.target.value);
                  }}
                />
              </Box>
              <Box mt={3}>
                <FormField
                  id={`field_showRanking`}
                  error={erroredFields("mobileHomeOptions.showRanking")}
                  definition={{
                    key: "privateApp",
                    type: "switch",
                    label: "Mostrar ranking",
                    help:
                      "Mostrar ranking de usuarios con sus puntos",
                  }}
                  size={12}
                  value={valueForPath("mobileHomeOptions.showRanking")}
                  onChange={(e) => {
                    setValueForPath("mobileHomeOptions.showRanking", e.target.value);
                  }}
                />
              </Box>
            </Box>
          </Paper>
        </Box>

        <Box m={2}>
          <Paper>
            <Box p={3}>
              <Typography component="h3" variant="h3">
                Opciones del hall (Virtual Venues)
              </Typography>
              <Box mt={3}>
                <FormField
                  id={`field_hallEnabled`}
                  error={erroredFields("plugins.virtualVenues.hall.active")}
                  definition={{
                    key: "hallEnabled",
                    type: "switch",
                    label: "Hall habilitado",
                    help:
                      "Habilitar una sala tipo hall como página principal de Virtual Venues",
                  }}
                  size={12}
                  value={valueForPath("plugins.virtualVenues.hall.active")}
                  onChange={(e) => {
                    setValueForPath("plugins.virtualVenues.hall.active", e.target.value);
                  }}
                />
              </Box>
              <Box mt={3}>
                <FormField
                  id={`field_chatActive`}
                  error={erroredFields("plugins.virtualVenues.chatActive")}
                  definition={{
                    key: "chatActive",
                    type: "switch",
                    label: "Chat habilitado",
                    help:
                      "Habilitar la funcionalidad de chat para usuarios logueados",
                  }}
                  size={12}
                  value={valueForPath("plugins.virtualVenues.chatActive")}
                  onChange={(e) => {
                    setValueForPath("plugins.virtualVenues.chatActive", e.target.value);
                  }}
                />
              </Box>
              <Box mt={3}>
                <FormField
                  id={`field_meetingsActive`}
                  error={erroredFields("plugins.virtualVenues.meetingsActive")}
                  definition={{
                    key: "meetingsActive",
                    type: "switch",
                    label: "Networking habilitado",
                    help:
                      "Habilitar la posibilidad de agendar video reuniones entre usuarios",
                  }}
                  size={12}
                  value={valueForPath("plugins.virtualVenues.meetingsActive")}
                  onChange={(e) => {
                    setValueForPath("plugins.virtualVenues.meetingsActive", e.target.value);
                  }}
                />
              </Box>
              <Box mt={3}>
                <FormField
                  id={`field_eventDate`}
                  error={erroredFields("plugins.virtualVenues.eventDate")}
                  definition={{
                    key: "eventDate",
                    type: "datetime",
                    label: "Inicio del evento",
                    help:
                      "Fecha y hora en la que empieza el evento",
                  }}
                  size={12}
                  value={valueForPath("plugins.virtualVenues.eventDate")}
                  onChange={(e) => {
                    setValueForPath("plugins.virtualVenues.eventDate", e.target.value);
                  }}
                />
              </Box>
              <Box mt={3}>
                <FormField
                  id={`field_hallDescription`}
                  error={erroredFields("plugins.virtualVenues.hall.description")}
                  definition={{
                    key: "hallDescription",
                    type: "text",
                    label: "Descripción",
                    help:
                      "Texto introductorio que aparecerá en la cabecera del hall",
                  }}
                  size={12}
                  value={valueForPath("plugins.virtualVenues.hall.description")}
                  onChange={(e) => {
                    setValueForPath("plugins.virtualVenues.hall.description", e.target.value);
                  }}
                />
              </Box>
              <Box mt={3}>
                <FormField
                  id={`field_hallEmbedType`}
                  error={erroredFields("plugins.virtualVenues.hall.embedType")}
                  definition={{
                    key: "hallEmbedType",
                    type: "select",
                    options: [
                      { key: 'iframe', label: 'iFrame'},
                      { key: 'none', label: 'Ninguno'},
                    ],
                    label: "Tipo de integración",
                  }}
                  size={12}
                  value={valueForPath("plugins.virtualVenues.hall.embedType")}
                  onChange={(e) => {
                    setValueForPath("plugins.virtualVenues.hall.embedType", e.target.value);
                  }}
                />
              </Box>
              <Box mt={3}>
                <FormField
                  id={`field_hallIntegrationRef`}
                  error={erroredFields("plugins.virtualVenues.hall.embedRef")}
                  definition={{
                    key: "hallIntegrationRef",
                    type: "string",
                    label: "Referencia",
                    help: "URL o referencia para la integración",
                  }}
                  size={12}
                  value={valueForPath("plugins.virtualVenues.hall.embedRef")}
                  onChange={(e) => {
                    setValueForPath("plugins.virtualVenues.hall.embedRef", e.target.value);
                  }}
                />
              </Box>
              <Box mt={3}>
                <MDEditor
                  value={valueForPath("plugins.virtualVenues.hall.embedDescription")||''}
                  onChange={(v) => {
                    setValueForPath("plugins.virtualVenues.hall.embedDescription", v);
                  }}
                />
              </Box>
              <Box mt={3}>
                <FormField
                  id={`field_showRooms`}
                  error={erroredFields("plugins.virtualVenues.hall.showRooms")}
                  definition={{
                    key: "showRooms",
                    type: "switch",
                    label: "Mostrar salas",
                    help:
                      "Mostrar el listado de salas virtuales en el hall",
                  }}
                  size={12}
                  value={valueForPath("plugins.virtualVenues.hall.showRooms")}
                  onChange={(e) => {
                    setValueForPath("plugins.virtualVenues.hall.showRooms", e.target.value);
                  }}
                />
              </Box>
            </Box>
          </Paper>
        </Box>

        <Box m={2}>
          <Paper>
            <Box p={3}>
              <Typography component="h3" variant="h3">
                Acceso a la app
              </Typography>
              <Box mt={3}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Acceso</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {admins.map( a => (
                        <TableRow key={a._id}>
                          <TableCell>{a.email}</TableCell>
                          <TableCell>{a.firstName} {a.lastName}</TableCell>
                          <TableCell>Propietario</TableCell>
                          <TableCell>
                            {a._id != props.services.app.feathersUser._id &&
                              <Button><Clear /></Button>
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box mt={3}>
                  <FormField
                    definition={{
                      key: "admin",
                      type: "string",
                      label: "Añadir usuario",
                      help:
                        "Añade cualquier usuario ya registrado como administrador.",
                    }}
                    size={12}
                    value={adminMail}
                    onChange={(e) => setAdminMail(e.target.value)}
                  />
                </Box>
                <Box mt={3} ml="auto">
                  <Button variant="contained" color="primary" onClick={addAdmin}>Añadir miembro</Button>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>

        <Box m={2}>
          <Paper>
            <Box p={3}>
              <Typography component="h3" variant="h3">
                Conexiones de API
              </Typography>
              <Box mt={3}>
                <FormField
                  id={`field_bundleId`}
                  error={erroredFields("bundleId")}
                  definition={{
                    key: "bundleId",
                    type: "string",
                    label: "Bundle ID",
                    help:
                      "Código de app en App Store. Necesario para el envío de notificaciones push.",
                  }}
                  size={12}
                  value={valueForPath("bundleId")}
                  onChange={(e) => {
                    setValueForPath("bundleId", e.target.value);
                  }}
                />
              </Box>
              <Box mt={3}>
                <FormField
                  id={`field_analyticsUrl`}
                  error={erroredFields("analyticsUrl")}
                  definition={{
                    key: "analyticsUrl",
                    type: "string",
                    label: "URL de analíticas",
                    help:
                      "URL que se mostrará en la pestaña de analíticas.",
                  }}
                  size={12}
                  value={valueForPath("analyticsUrl")}
                  onChange={(e) => {
                    setValueForPath("analyticsUrl", e.target.value);
                  }}
                />
              </Box>
            </Box>
          </Paper>
        </Box>

        <Box m={2}>
          <Paper>
            <Box p={3}>
              <Typography component="h3" variant="h3">
                Datos de la app
              </Typography>
              <Box mt={3}>

                <Box mt={3} ml="auto">
                  <Button variant="contained" color="primary" onClick={() => window.location = `${process.env.REACT_APP_API_URL}/reports/appexport?$excel=true&appId=${props.selectedApp.slug}`}>Descargar datos</Button>
                </Box>
                <Box mt={3} ml="auto">
                  <Button variant="contained" style={{ backgroundColor: '#EE0000', color: '#FFFFFF' }} onClick={addAdmin}>Eliminar app</Button>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );

  let navigate = useNavigate();
  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
          { title: props.selectedApp.name, path: `/${props.selectedApp.slug}/` },
          { title: "Ajustes de app" },
        ]}
      />
      <PageScreen className="Settings">
        <PageHeader title="Ajustes de app" renderActions={() => null} />
        <Form
          defaultValue={props.selectedApp}
          renderFields={renderFields}
          createAction={(payload) => props.actions.apps.create(payload)}
          patchAction={(payload) => props.actions.apps.patch(payload).then(() => props.actions.app.setSnack({ open: true, severity: 'success', message: "Ajustes guardados"}))}
          handleClose={() => {}}
          handleSuccess={(action) => {
            navigate(`../`);
          }}
        />
      </PageScreen>
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    apps: state.data.apps,
    services: {
      app: state.services.app
    }
  }),
  (dispatch) => ({
    actions: {
      apps: bindActionCreators(appsActionCreators, dispatch),
      app: bindActionCreators(appActionCreators, dispatch),
    },
  })
)(Settings);
