import React, { Fragment, useState, useEffect, useCallback } from "react";


import {
  FormControl, InputLabel, Select, MenuItem
} from "@material-ui/core";

import {
  CloudDownloadOutlined as DownloadIcon,
  CloudUploadOutlined as UploadIcon,
} from "@material-ui/icons";

import NavBar from "../../components/NavBar";
import PageHeader from "../../components/PageHeader";
import PageScreen from "../../components/PageScreen";
import HeaderButton from "../../components/HeaderButton";
import DataView from "../../components/DataView";
import RightModal from "../../components/RightModal/v2";

import { useConfirm } from "material-ui-confirm";

import { Link, useParams } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as usersActionCreators from "../../data/users/actions";

import FeathersHelper from '../../helpers/feathers';

import Switch from "@material-ui/core/Switch";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import TextField from '@material-ui/core/TextField';


import UserForm from "./UserForm";

import flags from './flags';

const BASE_QUERY = {
  $limit: -1,
  $sort: { lastName: 1 }
};

function getFullName(params) {
  return params.getValue ? `${params.getValue(params.id, 'firstName') || ''} ${
    params.getValue(params.id, 'lastName') || ''
  }` : 'caca';
}


const Users = (props) => {

  const MODAL_MANIFEST = [
    [
      {
        key: "invisible",
        type: "switch",
        label: "Usuario invisible",
      },
    ],
    [
      {
        key: "hideFromRanking",
        type: "switch",
        label: "Esconder en rankings",
      },
    ],
    [
      {
        key: "email",
        type: "string",
        label: "Email",
      },
    ],
    [
      {
        key: "firstName",
        type: "string",
        label: "Nombre",
      },
    ],
    [
      {
        key: "lastName",
        type: "string",
        label: "Apellidos",
      },
    ],
    [
      {
        key: "subtitle",
        type: "string",
        label: "Subtítulo",
      },
    ],
    [
      {
        key: "password",
        type: "string",
        label: "Contraseña",
      },
    ],
    [
      {
        key: "bio",
        type: "text",
        label: "Biografía",
      },
    ],
    [
      {
        key: "audiences",
        type: "multiAudience",
        label: "Audiencias",
        help: 'Elige a qué audiencias pertenece este usuario'
      },
    ],
    [
      {
        key: "country",
        type: "autocomplete",
        label: "Pais",
        help: 'Elige el pais del usuario',
        options: flags
      },
    ],
  ];
  const MODAL_LANG_MANIFEST = [
  ];

  useEffect(() => {
    props.actions.users.findItems({ query: BASE_QUERY });
    FeathersHelper.find('audiences', { query: { $limit: -1 } }).then( a => setAudiences(a));
  }, []);

  const [audiences, setAudiences] = useState([]);

  const [currentItem, setCurrentItem] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [query, setQuery] = useState("");

  const handleNew = () => {
    setCurrentItem({ audiences: []});
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    setCurrentItem(item);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setCurrentItem({});
  };

  const handleSearch = (e) => {
    const t = e.target.value;
    setQuery(t);
    props.actions.users.findItems({ query: {
      ...BASE_QUERY,
      $or: [
        { firstName: { $like: t, $search: t } },
        { lastName: { $like: t, $search: t } },
        { subtitle: { $like: t, $search: t } },
      ],
    } });
  }

  const audienceLabel = (id) => {
    const found = audiences.filter(a => a._id == id);
    return found.length > 0 ? found[0].name : 'Unknown';
  }

  const AudienceValue = useCallback((props) => {
    const { item, applyValue } = props;

    const handleFilterChange = (event) => {
      applyValue({ ...item, value: event.target.value });
    };

    return (
      <Select
        native
        value={item.value}
        onChange={handleFilterChange}
      >
        <option value='all'>Cualquiera</option>
        <option value='none'>Ninguna</option>
        {audiences.map(a => (
          <option value={a._id}>{a.name}</option>
        ))}
      </Select>
    );

    // return (
    //     <FormControl>
    //       <InputLabel id="demo-simple-select-label">Age</InputLabel>
    //       <Select
    //         labelId="demo-simple-select-label"
    //         id="demo-simple-select"
    //         value={item.value}
    //         onChange={handleFilterChange}
    //       >
    //         <MenuItem value='all'>Cualquiera</MenuItem>
    //         <MenuItem value='none'>Ninguna</MenuItem>
    //         {audiences.map(a => (
    //           <MenuItem value={a._id}>{a.name}</MenuItem>
    //         ))}
    //       </Select>
    //     </FormControl>
    // );
  }, [audiences]);

  const confirm = useConfirm();

  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
          { title: props.selectedApp.name, path: `/${props.selectedApp.slug}/` },
          { title: "Usuarios" },
        ]}
      />
      <PageScreen className="ListPage">
        <PageHeader
          title="Usuarios"
          renderActions={() => (
            <Fragment>
              <HeaderButton onClick={handleNew}>+ Nuevo usuario</HeaderButton>
              <HeaderButton to={"import"} component={Link}>
                Importar usuarios
              </HeaderButton>
            </Fragment>
          )}
        />
        <DataView
          useGrid
          items={props.users.items}
          total={props.users.total}
          loadPage={(page, perPage) => props.actions.users.findItems({ query: {
            ...BASE_QUERY,
            $limit: 10,
            $skip: (perPage * page),
            $or: [
              { firstName: { $like: query, $search: query } },
              { lastName: { $like: query, $search: query } },
              { subtitle: { $like: query, $search: query } },
            ],
          } })}
          columnDefs={[
            {
              field: "fullName",
              headerName: "Nombre",
              valueGetter: getFullName,
      //         sortComparator: (v1, v2, cellParams1, cellParams2) =>
      // getFullName(cellParams1).localeCompare(getFullName(cellParams2)),
              flex: 2
            },
            { field: "email", headerName: "E-mail", flex: 1 },
            { field: "subtitle", headerName: "Subtítulo", flex: 1 },
            {
              field: "audiences",
              headerName: "Audiencias",
              //valueGetter: (params) => (params.value||[]).map(a => audienceLabel(a)),
              renderCell: (params) => (
                <Fragment>
                  {(params.getValue(params.id, 'audiences')||[]).map((a,k) => (
                    <Chip
                      key={k}
                      color="primary"
                      size="small"
                      label={audienceLabel(a)}
                      className="Audience"
                    />
                  ))}
                </Fragment>
              ),
              sortComparator: (v1, v2, cellParams1, cellParams2) =>
                (cellParams1.value||[]).map(a => audienceLabel(a)).join(' ').localeCompare((cellParams2.value||[]).map(a => audienceLabel(a)).join(' ')),
              filterOperators: [
                {
                  label: 'Seleccionar audiencia',
                  value: 'from',
                  getApplyFilterFn: (filterItem) => {
                    if (
                      !filterItem.columnField ||
                      !filterItem.value ||
                      !filterItem.operatorValue
                    ) {
                      return null;
                    }

                    return (params) => {
                      console.log("Params value is", params.value);
                      console.log("filterItem is", filterItem.value);
                      if (filterItem.value == 'all') return true;
                      else if (filterItem.value == 'none' && params.value.length == 0) return true;
                      else return params.value.includes(filterItem.value);
                    };
                  },
                  InputComponent: AudienceValue,
                  InputComponentProps: { type: 'string' },
                },
              ],
              flex: 1
            },
            {
              field: "invisible",
              headerName: "Invisible",
              valueGetter: (params) => params.value ? 'Si' : 'No',
              flex: 1
            },
            {
              field: "actions",
              headerName: "Acciones",
              disableClickEventBubbling: true,
              renderCell: (params) => {
                const id = params.getValue(params.id, "_id");
                return (
                  <Fragment>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      className="EditButton"
                      onClick={() => {
                        const item = props.users.items.filter(i => i._id == id)[0];
                        handleEdit(item);
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      className="DeleteButton"
                      onClick={() => {
                        confirm({
                          title: "¿Eliminar usuario?",
                          description: `Esta acción eliminará por completo el
                          usuario, así como todo su contenido asociado.
                          Esta acción es permenente y no tiene marcha atrás.`,
                          confirmationText: "Eliminar",
                          confirmationButtonProps: { variant: "contained" },
                          cancellationText: "Cancelar",
                          cancellationButtonProps: { variant: "contained" },
                        })
                          .then(() => {
                            const item = props.users.items.filter(i => i._id == id)[0];
                            props.actions.users.remove(item);
                          })
                          .catch(() => {});
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Fragment>
                );
              }
            },
            // { ref: "name", align: "left" },
            // { ref: "email", align: "left" },
            // { ref: "subtitle", align: "left" },
            // { ref: "audiences", align: "left", style: {width: '30%'} },
            // { ref: "invisible", align: "left" },
            // { ref: "actions", align: "left" },
          ]}
          renderHeader={(ref) => {
            switch (ref) {
              case "name":
                return "Nombre";
              case "email":
                return "E-mail";
              case "subtitle":
                return "Subtítulo";
              case "audiences":
                return "Audiencias";
              case "invisible":
                return "Invisible";
              case "actions":
                return "Acciones";
              default:
                return null;
            }
          }}
          renderCell={(ref, item) => {
            switch (ref) {
              case "name":
                return `${item.firstName} ${item.lastName}`;
              case "email":
              case "subtitle":
                return item[ref];
              case "audiences":
                return (
                  <Fragment>
                    {(item.audiences||[]).map(a => (
                      <Chip
                        key={a}
                        color="primary"
                        size="small"
                        label={audienceLabel(a)}
                        className="Audience"
                      />
                    ))}

                  </Fragment>
                );
              case "invisible":
                return (
                  <Switch
                    color="primary"
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    onChange={(event) =>
                      props.actions.users.patch(item, {
                        invisible: event.target.checked,
                      })
                    }
                    checked={item.invisible}
                  />
                );
              case "actions":
                return (
                  <Fragment>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      className="EditButton"
                      onClick={() => handleEdit(item)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      className="DeleteButton"
                      onClick={() => {
                        confirm({
                          title: "¿Eliminar usuario?",
                          description: `Esta acción eliminará por completo el
                          usuario, así como todo su contenido asociado.
                          Esta acción es permenente y no tiene marcha atrás.`,
                          confirmationText: "Eliminar",
                          confirmationButtonProps: { variant: "contained" },
                          cancellationText: "Cancelar",
                          cancellationButtonProps: { variant: "contained" },
                        })
                          .then(() => {
                            props.actions.users.remove(item);
                          })
                          .catch(() => {});
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Fragment>
                );
              default:
                return null;
            }
          }}
          empty={{
            title: "Añade tu primer usuario",
            body:
              "Añade usuarios a tu app y permíteles así acceder con sus credenciales.",
            cta: "+ Crear nuevo usuario",
            action: handleNew,
          }}
        />
      </PageScreen>
      <RightModal
        newTitle="Crear usuario"
        editTitle="Editar usuario"
        open={modalOpen}
        currentItem={currentItem}
        handleClose={handleClose}
        createAction={props.actions.users.create}
        patchAction={props.actions.users.patch}
        manifest={MODAL_MANIFEST}
        langManifest={MODAL_LANG_MANIFEST}
        actions={props.actions.users}
      />
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    users: state.data.users,
  }),
  (dispatch) => ({
    actions: {
      users: bindActionCreators(usersActionCreators, dispatch),
    },
  })
)(Users);
