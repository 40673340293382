import React, { Fragment, useEffect, useState } from "react";

import { Switch, Chip, IconButton } from "@material-ui/core/";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons/";

import NavBar from "../../components/NavBar";
import PageHeader from "../../components/PageHeader";
import PageScreen from "../../components/PageScreen";
import DataView from "../../components/DataView";
import RightModal from "../../components/RightModal/v2";

import { useConfirm } from "material-ui-confirm";

// HOCs
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as notificationsActionCreators from "../../data/notifications/actions";
import HeaderButton from "../../components/HeaderButton";

import Moment from "react-moment";
import nativeMoment from "moment-timezone";
import "moment/locale/es";

import FeathersHelper from "../../helpers/feathers";

const BASE_QUERY = {
  $limit: 10,
  $sort: { createdAt: -1 }
};

const Notifications = (props) => {

  useEffect(() => {
    props.actions.notifications.findItems({
      query: BASE_QUERY
    });
    FeathersHelper.find('modules', { query: { $limit: -1 } }).then( m => {
      setModalManifest([
        [
          {
            key: "target.everyone",
            type: "switch",
            options: [
              { key: '', label: ''}
            ],
            label: "Visible para todo el mundo",
            help: "Todos los usuarios que descarguen la app recibirán la notificación",
            onChange: (checked) => {
              const wrap = document.getElementById("field_target.audiences");
              wrap.style.display = checked ? "none" : "block";
            }
          },
        ],
        [
          {
            key: "target.audiences",
            type: "multiAudience",
            label: "Audiencias",
            help: 'Elige a qué audiencias pertenece este usuario'
          },
        ],
        [
          {
            key: "immediate",
            type: "switch",
            label: "Inmediata",
            help: "La notificación se enviará ahora",
            onChange: (checked) => {
              const wrap = document.getElementById("sendAfterWrap");
              wrap.style.display = checked ? "none" : "block";
              //wrap.querySelectorAll("input")[0].value = '';
            }
          },
        ],
        [
          {
            id: "sendAfterWrap",
            key: "sendAfter",
            type: "datetime",
            timezoneKey: "timezone",
            label: "Hora programada",
            help: "Hora a la que se mandará la notificación. Si no se especifica será inmediata",
          },
        ],
        [
          {
            key: "title",
            multilang: true,
            type: "string",
            label: "Título",
            help: "Título de la notificación",
          },
        ],
        [
          {
            key: "body",
            multilang: true,
            type: "text",
            label: "Texto",
            help: "Texto de la notificación",
          },
        ],
        [
          {
            key: "action.type",
            type: "select",
            label: "Acción",
            options: [
              { key: 'none', label: 'Ninguna' },
              { key: 'module', label: 'Navegar a un módulo' },
            ],
            help: "Tipo de acción al abrir la notificación",
            onChange: (value) => {
              const wrap = document.getElementById("actionModuleWrap");
              wrap.style.display = value == 'module' ? "block" : "none";
              //wrap.querySelectorAll("input")[0].value = '';
            }
          },
        ],
        [
          {
            id: "actionModuleWrap",
            key: "action.moduleId",
            type: "select",
            label: "Módulos",
            options: m.map( mod => ({ key: mod._id, label: mod.name }) ),
            help: "Módulo al que llevará la notificación",
          },
        ],
      ]);
    });
  }, []);

  const [modalManifest, setModalManifest] = useState([]);
  const [currentItem, setCurrentItem] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const handleNew = () => {
    setCurrentItem({
      embedType: "none",
      action: {
        type: 'none'
      },
      target: {
        everyone: true,
        audiences: []
      },
      //sendAfter: nativeMoment().format('YYYY-MM-DDTHH:mm:ss'),
      timezone: 'Europe/Madrid'
    });
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    const newItem = { ...item };
    //alert(JSON.stringify(item));
    if (newItem.sendAfter) {
      //newItem.sendAfter = nativeMoment.tz(newItem.sendAfter, newItem.timezone||'Europe/Madrid').format('YYYY-MM-DDTHH:mm:ss');
      newItem.sendAfter = nativeMoment.tz(newItem.sendAfter, newItem.timezone||'Europe/Madrid').toDate();
    }
    //alert(newItem.sendAfter);
    setCurrentItem(newItem);
    setModalOpen(true);
  };

  const handleClose = () => {
    setCurrentItem({});
    setModalOpen(false);
  };

  const confirm = useConfirm();

  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
          { title: props.selectedApp.name },
          { title: "Notificaciones" },
        ]}
      />
      <PageScreen className="ListPage">
        <PageHeader
          title="Notificaciones"
          renderActions={() => (
            <Fragment>
              <HeaderButton onClick={handleNew}>
                + Nueva notificación
              </HeaderButton>
            </Fragment>
          )}
        />
        <DataView
          useTable2
          //loading={props.notifications.loading}
          items={props.notifications.items}
          total={props.notifications.total}
          loadPage={(page, perPage) => props.actions.notifications.findItems({ query: {
            ...BASE_QUERY,
            $skip: (perPage * page),
          } })}
          columnDefs={[
            { ref: "title", align: "left", style: {width: '30%'} },
            { ref: "target", align: "left" },
            { ref: "immediate", align: "left" },
            { ref: "sendAfter", align: "left" },
            { ref: "sent", align: "left" },
            { ref: "actions", align: "left" },
          ]}
          renderHeader={(ref) => {
            switch (ref) {
              case "title":
                return "Título";
              case "target":
                return "Destino";
              case "immediate":
                return "Inmediata";
              case "sendAfter":
                return "Fecha";
              case "sent":
                return "Enviada";
              case "actions":
                return "Acciones";
              default:
                return null;
            }
          }}
          renderCell={(ref, item) => {
            switch (ref) {
              case "title":
                return (
                  <Fragment>
                    <strong>{item.title.es}</strong>
                    <br />{item.body.es}
                  </Fragment>
                );
              case "target":
                if (item[ref].everyone) return "Todos";
                else return "Algunos";
              case "immediate":
                return item.sendAfter ? "No" : "Si";
              case "sendAfter":
                //return item.sendAfter || item.createdAt;
                return nativeMoment.tz(item.sendAfter || item.createdAt, item.timezone||'Europe/Madrid').format('L HH:mm') + ` (${item.timezone||'Europe/Madrid'})`;
              case "sent":
                return item.sent ? "Si" : "No";
              case "actions":
                return (
                  <Fragment>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      className="EditButton"
                      onClick={() => handleEdit(item)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      className="DeleteButton"
                      onClick={() => {
                        confirm({
                          title: "¿Eliminar notificación?",
                          description: `Esta acción eliminará por completo la
                          notificación, así como todo su contenido asociado.
                          Esta acción es permenente y no tiene marcha atrás.`,
                          confirmationText: "Eliminar",
                          confirmationButtonProps: { variant: "contained" },
                          cancellationText: "Cancelar",
                          cancellationButtonProps: { variant: "contained" },
                        })
                          .then(() => {
                            props.actions.notifications.remove(item);
                          })
                          .catch(() => {});
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Fragment>
                );
              default:
                return null;
            }
          }}
          empty={{
            title: "Envía notificaciones a tus usuarios",
            body:
              "Notifica a todos o parte de los usuarios de tu aplicación mediante una notificación push. También la puedes programar para otro momento.",
            cta: "+ Crear nueva notificación",
            action: handleNew,
          }}
        />
      </PageScreen>
      <RightModal
        newTitle="Crear nueva notificación"
        editTitle="Editar notificación"
        open={modalOpen}
        currentItem={currentItem}
        handleClose={handleClose}
        createAction={props.actions.notifications.create}
        patchAction={props.actions.notifications.patch}
        manifest={modalManifest}
        dependencies={{
          immediate: {
            true: { path: 'sendAfter', value: '' }
          },
          'target.everyone': {
            true: { path: 'target.audiences', value: [] }
          },
        }}
      />
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    notifications: state.data.notifications,
  }),
  (dispatch) => ({
    actions: {
      notifications: bindActionCreators(notificationsActionCreators, dispatch),
    },
  })
)(Notifications);
