import React from "react";

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import BounceLoader from "react-spinners/BounceLoader";

export default (props) => (
  <Backdrop style={{}} open={true}>
    <CircularProgress color="primary" />
    {/* <BounceLoader style={{ position: 'absolute', margin: '0 auto' }} size={220} color="#FBCA40" loading={true} /> */}
  </Backdrop>
);
