import React, { useState, useEffect, Fragment } from 'react';

import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  Paper
} from "@material-ui/core";

import NavBar from "../../components/NavBar";
import PageHeader from "../../components/PageHeader";
import PageScreen from "../../components/PageScreen";
import HeaderButton from "../../components/HeaderButton";

import { Link, useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appsActionCreators from "../../data/apps/actions";

import { useBottomScrollListener } from "react-bottom-scroll-listener";

import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/es";
import { ScaledImage, scaledImageSource } from '../../components/ScaledImage';


const Home = props => {

  const navigate = useNavigate();

  useBottomScrollListener(() => {
    //alert("Bottom!");
  })

  useEffect(() => {
    props.actions.apps.findItems({ query: { $sort: { createdAt: 1 }, $limit: -1 } });
  }, []);

  const renderEmpty = () => {
    return (
      <Container maxWidth="xl" className="ContainerGrow">
          <Grid container justify="center" alignItems="center" spacing={2}>
            <Grid item md={6} lg={4} className="centered">
              <Typography component="h2" variant="h2" align="center">
                Crea una aplicación móvil atractiva para tus eventos
              </Typography>
              <Box mt={1}>
                <Typography align="center" mt={2}>
                  Construye rápidamente y previsualiza al instante Todas las
                  funciones están incluidas, solo cree y publique Go live en
                  minutos y comience a incorporar asistentes
                </Typography>
              </Box>
              <Box mt={2}>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                >
                  + Crear nueva App
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
    );
  };

  const renderItems = () => {
    return (
        <Box pb={4}>
          <Grid container spacing={4}>
            {props.apps.map((i) => (
              <Grid item md={4} sm={6} xs={12}>
                <Paper onClick={() => window.location = `/${i.slug}/` } component={Paper} className="BelongApp">
                  <Box p={3}>
                    <div className="Header">
                      <div
                        className="Icon"
                        style={{
                          backgroundImage: `url(${scaledImageSource(i.icon, 120, 120)})`,
                        }}
                      ></div>

                      <div className="Buttons">
                        <Button color="light" variant="contained">
                          Edit
                        </Button>
                        <Button
                          color="light"
                          variant="contained"
                          className="More"
                        >
                          ...
                        </Button>
                      </div>
                    </div>

                    <Typography component="h3" variant="h3">
                      {i.name}
                    </Typography>
                    <Typography component="p">
                      Creada el{" "}
                      <Moment locale="es" format="dddd LL">
                        {i.createdAt}
                      </Moment>
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            ))}
            <Grid item md={4} sm={6} xs={12}>
              <Paper className="BelongApp New" onClick={() => navigate(`/new`)}>
                <Box p={3}>
                  <Typography component="h3" variant="h3" align="center">
                    +<br />
                    Crear nueva aplicación
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
    );
  };


  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
        ]}
        isHome
      />
      <PageScreen className="Home">
        <PageHeader
          title="Apps"
          renderActions={() => (
            <Fragment>
              <HeaderButton component={Link}
                to={'/new'}>+ Nueva app</HeaderButton>
            </Fragment>
          )}
        />
        {props.apps.length == 0 ? renderEmpty() : renderItems()}
      </PageScreen>
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    apps: state.data.apps.items,
  }),
  (dispatch) => ({
    actions: {
      apps: bindActionCreators(appsActionCreators, dispatch),
    },
  })
)(Home);