import React, { Fragment, useEffect, useState } from "react";

import { Switch, Chip, IconButton } from "@material-ui/core/";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons/";

import NavBar from "../../components/NavBar";
import PageHeader from "../../components/PageHeader";
import PageScreen from "../../components/PageScreen";
import DataView from "../../components/DataView";
import RightModal from "../../components/RightModal/v2";

import { useConfirm } from "material-ui-confirm";

import { Link, useParams } from "react-router-dom";

// HOCs
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modulesActionCreators from "../../data/modules/actions";
import HeaderButton from "../../components/HeaderButton";
import ModulePicker from "./components/ModulePicker";

import FeathersHelper from "../../helpers/feathers";

const BASE_QUERY = {
  $sort: { position: 1 },
  $limit: -1
};

const Modules = (props) => {

  const MODULE_TYPES = [
    {
      key: "agendaModules",
      icon: "calendar",
      title: "Agenda",
      description:
        "Crea un calendario con los próximos eventos que conforman tu evento",
      extraFields: []
    },
    {
      key: "questionsModules",
      icon: "question-mark-circle",
      title: "Preguntas",
      description:
        "Crea un calendario con los próximos eventos que conforman tu evento",
      extraFields: [
        [
          {
            key: "showsFeed",
            type: "switch",
            label: "Modo chat",
            help: "Activar el modo chat",
          },
        ],
        [
          {
            key: "hint",
            type: "string",
            multilang: true,
            label: "Hint",
            help: "Subtítulo para incentivar preguntas",
          },
        ],
        [
          {
            key: "topicsPlaceholder",
            type: "string",
            multilang: true,
            label: "Plaheholder de temas",
            help: "Frase del selector de temas",
          },
        ]
      ]
    },
    {
      key: "votingModules",
      icon: "trending-up",
      title: "Votaciones",
      description:
        "Crea un calendario con los próximos eventos que conforman tu evento",
      extraFields: [
        [
          {
            key: "backgroundImage",
            type: "image",
            aspectRatio: 1.7,
            borderRadius: 10,
            label: "Imagen de fondo (Proy. 3D)",
            help: "Imagen de fondo para proyecciones 3D",
          },
        ],
        [
          {
            key: "backgroundHue",
            type: "string",
            label: "Rotación tonal",
            help: "Rotación tonal (en grados) de la imagen de fondo de la proyección 3D",
          },
        ],
        [
          {
            key: "hideLogo",
            type: "switch",
            label: "Esconder Logo",
            help: "Esconder logo en gráficas 3D",
          },
        ],
        [
          {
            key: "colors",
            type: "stringArray",
            label: "Paleta",
            help: "Paleta de colores para las gráficas 3D",
          },
        ],
      ]
    },
    {
      key: "gamingModules",
      icon: "trending-up",
      title: "Gamificación",
      description:
        "Crea un calendario con los próximos eventos que conforman tu evento",
      extraFields: []
    },
    {
      key: "timelineModules",
      icon: "trending-up",
      title: "Muro social",
      description:
        "Crea un muro social",
      extraFields: [
        [
          {
            key: "gamingActive",
            type: "switch",
            label: "Gamificar",
            help: "Activar puntuación según la interacción de los usuarios",
          },
        ],
        [
          {
            key: "pointsPerPost",
            type: "string",
            label: "Puntos por postear",
            help: "Se sumarán puntos por cada post creado por el usuario",
          },
        ],
        [
          {
            key: "pointsPerLike",
            type: "string",
            label: "Puntos por like emitido",
            help: "Se sumarán puntos por cada like hecho por el usuario",
          }
        ],
        [
          {
            key: "pointsPerLikeReceived",
            type: "string",
            label: "Puntos por like recibido",
            help: "Se sumarán puntos por cada like en un post del usuario",
          }
        ],
        [
          {
            key: "pointsPerComment",
            type: "string",
            label: "Puntos por comentario emitido",
            help: "Se sumarán puntos por cada comentario hecho por el usuario",
          }
        ],
        [
          {
            key: "pointsPerCommentReceived",
            type: "string",
            label: "Puntos por comentario recibido",
            help: "Se sumarán puntos por cada comentario en un post del usuario",
          }
        ],
      ]
    },
    {
      key: "staticModules",
      icon: "trending-up",
      title: "Estático",
      description:
        "Crea un módulo con contenido estático",
      extraFields: []
    },
    {
      key: "webtabModules",
      icon: "globe",
      title: "Webtab",
      description:
        "Crea un módulo con el contenido de una URL",
      extraFields: [
        [
          {
            key: "content",
            type: "string",
            multilang: true,
            label: "URL destino",
            help: "URL que se abrirá dentro de la app al navegar al módulo",
          }
        ],
      ]
    },
    {
      key: "userListModules",
      icon: "users",
      title: "Lista de usuarios",
      description:
        "Muestra el listado general de usuarios de la app como un módulo más",
      extraFields: []
    },
  ];

  const MODAL_MANIFEST = [
    [
      {
        key: "icon",
        type: "icon",
        label: "Icono",
        help: "Elige un icono para el módulo",
      },
    ],
    [
      {
        key: "name",
        type: "string",
        label: "Nombre del módulo",
        help: "Nombre interno para identificar el módulo",
      },
    ],
    [
      {
        key: "title",
        type: "string",
        multilang: true,
        label: "Título del módulo",
        help: "Título del módulo dentro de la app.",
      },
    ],
    [
      {
        key: "visibility",
        type: "audienceVisibility",
        label: "Visibilidad",
        help: "Elige quién puede ver este módulo",
      },
    ],
  ];
  const MODAL_LANG_MANIFEST = [

  ];

  const initialLoad = (setLoading = true) => {
    props.actions.modules.findItems({
      query: {
        ...BASE_QUERY
      }
    }, setLoading);
    FeathersHelper.find('audiences', { query: { $limit: -1 } }).then( a => setAudiences(a));
  }

  useEffect(() => {
    initialLoad();
  }, []);

  const [audiences, setAudiences] = useState([]);
  const [currentItem, setCurrentItem] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const handleNew = () => {
    setCurrentItem({
      visibility: {
        everyone: true
      },
      position: 99
    });
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    setCurrentItem(item);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setCurrentItem({});
  };

  const audienceLabel = (id) => {
    const found = audiences.filter(a => a._id == id);
    return found.length > 0 ? found[0].name : 'Unknown';
  }

  const confirm = useConfirm();

  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
          { title: props.selectedApp.name },
        ]}
      />
      <PageScreen className="ListPage">
        {props.modules.items.length !== 0 && (
          <PageHeader
            title="Módulos"
            renderActions={() => (
              <Fragment>
                <HeaderButton onClick={handleNew}>+ Nuevo módulo</HeaderButton>
              </Fragment>
            )}
          />
        )}
        <DataView
          loading={props.modules.loading}
          items={props.modules.items}
          handleReorder={(id, oldIndex, newIndex) => {
            //FeathersHelper.app.reorder(id, oldIndex, newIndex);
            FeathersHelper.app.service("modules").patch(id, { reorder: true, position: newIndex+1 }).then(r => {
              initialLoad(false);
            }).catch(e => console.log(e));
          }}
          columnDefs={[
            { ref: "name", align: "left" },
            { ref: "_type", align: "left" },
            { ref: "audiences", align: "left" },
            { ref: "activation", align: "left" },
            { ref: "actions", align: "left" },
          ]}
          renderHeader={(ref) => {
            switch (ref) {
              case "name":
                return "Nombre";
              case "_type":
                return "Tipo";
              case "audiences":
                return "Audiencias";
              case "activation":
                return "Activación";
              case "actions":
                return "Acciones";
              default:
                return null;
            }
          }}
          renderCell={(ref, item) => {
            switch (ref) {
              case "name":
                switch(item._type) {
                  case 'agendaModules':
                    return (
                      <Link to={`module/${item._id}/appointments`}>
                        <span className={`icon-md-${item.icon} Icon`}></span>
                        {item[ref]}
                      </Link>
                    );
                  case 'staticModules':
                    return (
                      <Link to={`module/${item._id}/static-contents`}>
                        <span className={`icon-md-${item.icon} Icon`}></span>
                        {item[ref]}
                      </Link>
                    );
                  case 'questionsModules':
                    return (
                      <Link to={`module/${item._id}/questions`}>
                        <span className={`icon-md-${item.icon} Icon`}></span>
                        {item[ref]}
                      </Link>
                    );
                  case 'gamingModules':
                    return (
                      <Link to={`module/${item._id}/games`}>
                        <span className={`icon-md-${item.icon} Icon`}></span>
                        {item[ref]}
                      </Link>
                    );
                  case 'votingModules':
                    return (
                      <Link to={`module/${item._id}/voting-blocks`}>
                        <span className={`icon-md-${item.icon} Icon`}></span>
                        {item[ref]}
                      </Link>
                    );
                  case 'timelineModules':
                    return (
                      <Link to={`module/${item._id}/posts`}>
                        <span className={`icon-md-${item.icon} Icon`}></span>
                        {item[ref]}
                      </Link>
                    );
                  default:
                    return (
                      <a>
                        <span className={`icon-md-${item.icon} Icon`}></span>
                        {item[ref]}
                      </a>
                    );
                }

              case "_type":
                const mts = MODULE_TYPES.filter((mt) => mt.key == item[ref]);
                return mts.length > 0 ? mts[0].title : "Undefined";
              case "audiences":
                return (
                  item.visibility.audiences && item.visibility.audiences.length > 0 ?
                    <Fragment>
                      {(item.visibility.audiences||[]).map(a => (
                        <Chip
                          key={a}
                          color="primary"
                          size="small"
                          label={audienceLabel(a)}
                          className="Audience"
                        />
                      ))}
                    </Fragment>
                    :
                    <Chip
                      color="secondary"
                      size="small"
                      label="Todos los usuarios"
                      className="Audience"
                    />
                );
              case "activation":
                return (
                  <Switch
                    color="primary"
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    onChange={(event) =>
                      props.actions.modules.patch(item, {
                        active: event.target.checked,
                      })
                    }
                    checked={item.active}
                  />
                );
              case "actions":
                return (
                  <Fragment>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      className="EditButton"
                      onClick={() => handleEdit(item)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      className="DeleteButton"
                      onClick={() => {
                        confirm({
                          title: "¿Eliminar módulo?",
                          description: `Esta acción eliminará por completo el
                          módulo, así como todo su contenido asociado.
                          Esta acción es permenente y no tiene marcha atrás.`,
                          confirmationText: "Eliminar",
                          confirmationButtonProps: { variant: "contained" },
                          cancellationText: "Cancelar",
                          cancellationButtonProps: { variant: "contained" },
                        })
                          .then(() => {
                            props.actions.modules.remove(item);
                          })
                          .catch(() => {});
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Fragment>
                );
              default:
                return null;
            }
          }}
          empty={{
            title: "Añada módulos increíbles para impulsar su aplicación",
            body:
              "Construye rápidamente y previsualiza al instante todas las funciones están incluidas, solo cree y publique go live en minutos y comience a incorporar asistentes",
            cta: "+ Crear nuevo módulo",
            action: handleNew,
          }}
        />
      </PageScreen>
      <RightModal
        newTitle="Crear módulo"
        editTitle="Editar módulo"
        open={modalOpen}
        currentItem={currentItem}
        handleClose={handleClose}
        createAction={props.actions.modules.create}
        patchAction={props.actions.modules.patch}
        manifest={currentItem._type ? [...MODAL_MANIFEST, ...MODULE_TYPES.filter(mt => mt.key == currentItem._type)[0].extraFields] : MODAL_MANIFEST}
        langManifest={MODAL_LANG_MANIFEST}
        contentOverrideEnabled={!currentItem._type && !currentItem._id}
        contentOverride={
          <ModulePicker
            moduleTypes={MODULE_TYPES}
            moduleTypeSelected={(mt) => {
              setCurrentItem((ci) => ({ ...ci, _type: mt }));
            }}
            newTitle="Crear módulo"
          />
        }
      />
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    modules: state.data.modules,
  }),
  (dispatch) => ({
    actions: {
      modules: bindActionCreators(modulesActionCreators, dispatch),
    },
  })
)(Modules);
