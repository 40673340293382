import React, { useState } from 'react';

import { Grid, Paper, Box, Typography, List, ListItem } from "@material-ui/core/";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import uuid from "uuid/v4";

import { makeStyles } from "@material-ui/core/styles";

const draggable = require('../../../../img/draggable.svg');

const useStyles = makeStyles((theme) => ({
  listTitle: {
    color: "#686E71",
    fontWeight: 500,
    fontSize: "0.6875rem",
    textTransform: "uppercase",
    marginBottom: 18,
  },
  moduleItem: {
    border: "1px solid #EEEEEF",
    backgroundColor: "#fff",
    borderRadius: 10,
    marginBottom: 9,
    padding: "1rem 0.6875rem",
    display: "flex",
    flexDirection: "row",
    "&:last-child": {
      marginBottom: 0,
    },
  },
  dragHere: {
    border: "1px dashed #DADEE1",
    backgroundColor: "transparent",
    "& span": {
      flexGrow: 1,
      color: "#B4B4B7",
      fontSize: "0.75rem",
      textAlign: 'center'
    },
  },
  draggable: {
    marginRight: "1rem",
  },
  selected: {
    backgroundColor: "#FAFAFA",
    border: "1px solid #EEEEEF",
    flexGrow: 1,
    padding: 10,
  },
}));

const MODULE_TYPES = [
  { id: uuid(), type: "agendaModules", title: "Agenda" },
  { id: uuid(), type: "questionsModules", title: "Preguntas" },
  { id: uuid(), type: "votingModules", title: "Votaciones" },
];

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
/**
 * Moves an item from one list to another list.
 */
const copy = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const item = sourceClone[droppableSource.index];

    destClone.splice(droppableDestination.index, 0, { ...item, id: uuid() });
    return destClone;
};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};



export default props => {

  const classes = useStyles();

  const [state, setState] = useState({});

  const [selected, setSelected] = useState([]);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    // dropped outside the list
    console.log("RESULT", result)
    if (!destination) {
      //alert("No dest")
      return;
    }

    switch (source.droppableId) {
      case destination.droppableId:
        // alert("Same");
        setSelected((selected) => {
          const value = reorder(selected, source.index, destination.index);
          props.onChange({ target: { value: value } });
          return value;
        } );
        break;
      case "available":
        //alert("Diffreent");
        setSelected((selected) => {
          const value = [...selected, { ...MODULE_TYPES[source.index], id: uuid() }];
          props.onChange({ target: { value: value } });
          return value;
        });
        break;
      default:
          alert("default");
        // setState( state =>
        //   move(
        //     state[source.droppableId],
        //     state[destination.droppableId],
        //     source,
        //     destination
        //   )
        // );
        break;
    }
  }


  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid container spacing={2} style={{ alignItems: "stretch" }}>
        <Grid item md={6}>
          <Typography component="h4" variant="h5" className={classes.listTitle}>
            Disponibles
          </Typography>

          <Droppable droppableId="available" isDropDisabled={true}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
              >
                {MODULE_TYPES.map((item, key) => (
                  <Draggable key={item.id} draggableId={item.id} index={key}>
                    {(provided, snapshot) => (
                      <React.Fragment>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          isDragging={snapshot.isDragging}
                          className={classes.moduleItem}
                        >
                          <img src={draggable} className={classes.draggable} />
                          <span>{item.title}</span>
                        </div>
                        {snapshot.isDragging && (
                          <div className={classes.moduleItem}>
                            <img
                              src={draggable}
                              className={classes.draggable}
                            />
                            <span>{item.title}</span>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </Grid>
        <Grid item md={6} style={{ display: "flex", flexDirection: "column" }}>
          <Typography component="h4" variant="h5" className={classes.listTitle}>
            Seleccionados
          </Typography>
          <Paper className={classes.selected}>
            <Droppable key="selected" droppableId="selected">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  isDraggingOver={snapshot.isDraggingOver}
                >
                  {selected.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          isDragging={snapshot.isDragging}
                          className={classes.moduleItem}
                        >
                          <img src={draggable} className={classes.draggable} />
                          <span>{item.title}</span>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {selected.length === 0 ? (
                    <div
                      className={`${classes.moduleItem} ${classes.dragHere}`}
                    >
                      <span>Drop items here</span>
                    </div>
                  ) : (
                    provided.placeholder
                  )}
                </div>
              )}
            </Droppable>
          </Paper>
        </Grid>
      </Grid>
    </DragDropContext>
  );
}