import React from 'react';

export const scaledImageSource = (key, width = 300, height = 300, faceCrop = false) => {
  const request = {
    bucket: "ubelong-dev2", // S3 bucket name
    key: key, // path to your file on the S3 bucket (ex. photos/face1.png)
    edits: {
      // smartCrop: faceCrop ? {
      //   faceIndex: 0,   // zero-based index of detected faces
      //   padding: 130    // padding expressed in pixels, applied to all sides
      // } : false,
      normalize: true,
      // small tip, if you have a mobile app you can use the following 2 lines of code to optimise your images further
      webp: true, // if android, android loves webp
      jpeg: true, // if iOS, iOS doesnt display webp so lets convert it
      resize: {
        width: width,
        height: height,
        fit: "cover",
      },
    },
  };
  const strRequest = JSON.stringify(request);
  const encRequest = btoa(strRequest);
  let url = `https://d14kpv59isoic6.cloudfront.net/${encRequest}`; // the image url

  return url;
};

export const ScaledImage = props => {
  console.log(props);
  return (
    props.imgkey ?
      <img src={scaledImageSource(props.imgkey, props.width, props.height)} className={props.className} />
    :
      <div className={props.className} />
  );
}