import React from 'react';

import {
  List,
  ListSubheader,
  RootRef,
  makeStyles
} from "@material-ui/core/";

import { ReactSortable } from "react-sortablejs";

import StaticContentListItem from "./components/StaticContentListItem";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  subHeader: {
    backgroundColor: 'transparent',
    fontSize: 11,
    textTransform: 'uppercase',
    color: '#4B4B4B',
    borderBottom: '1px solid #E2E3E4',
    marginBottom: 10
  },
  firstItem: {
    alignSelf: 'center',
    color: '#BBBBBB',
    margin: '20px 0'
  }
}));

export default ({setSelectedPath, contents, setContents}) => {

  const classes = useStyles();

  return (
    <List
      component="div"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader" className={classes.subHeader}>
          Lista de contenidos
        </ListSubheader>
      }
      className={classes.root}
    >
      {contents.length == 0 &&
        <span className={classes.firstItem}>Añade el primer item...</span>
      }
      <ReactSortable
        list={contents}
        setList={setContents}
        group="groupName"
        emptyInsertThreshold={400}
      >

        {contents.map( (c, i) => (
          <StaticContentListItem key={c._id} content={c} setSelectedPath={setSelectedPath} setContents={setContents} basePath={`[${i}]`} index={i} />
        ))}
      </ReactSortable>
    </List>
  );
}