import * as api from "./api";
import * as actionTypes from "./actionTypes";

const update = (items, total) => ({
  type: actionTypes.UPDATE,
  items,
  total
});

export const append = (items, total) => ({
  type: actionTypes.APPEND,
  items,
  total
});

export const updateOne = (item) => ({
  type: actionTypes.UPDATE_ONE,
  item,
});

export const destroy = (item) => ({
  type: actionTypes.DESTROY,
  item,
});

const loading = () => ({
  type: actionTypes.LOADING
});

export const findItems = params => dispatch => {
  dispatch(loading());
  return api
    .find(params)
    .then((r) => dispatch(r.data ? update(r.data, r.total) : update(r)));
}

export const create = (payload) => (dispatch) =>
         api.create(payload).then((r) => dispatch(append([r])));

export const patch = (item, payload) => (dispatch) => api
    .patch(item._id, payload)
    .then((r) => dispatch(updateOne(r)));

export const remove = (item) => (dispatch) =>
  api.remove(item._id).then((r) => dispatch(destroy(r)));