import React from 'react';

import {
  Container,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";

export default props => {

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className="PageHeader">
          <Typography component="h2" variant="h3">
            {props.title}
          </Typography>
          {props.renderActions()}
        </div>
      </Grid>
    </Grid>
  );
}