import React, { useEffect, useState } from 'react';

import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  Paper,
  Button,
  Switch
} from "@material-ui/core/";

import { makeStyles } from '@material-ui/core/styles';

import { useConfirm } from "material-ui-confirm";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modulesActionCreators from "../../../data/modules/actions";
import * as questionsActionCreators from "../../../data/questions/actions";


import FeathersHelper from '../../../helpers/feathers';

import { useParams } from "react-router-dom";

import nativeMoment from "moment-timezone";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const PAGE_STEP = 10;

const QuestionReport = props => {

  const [showTotal, setShowTotal] = useState(PAGE_STEP);

  const classes = useStyles();

  let { moduleId } = useParams();

  let baseQuery;

  if (props.master) {
    baseQuery = { moduleId };
  } else {
    baseQuery = { moduleId, tailored: true };
  }

  const initialLoad = () => {
    props.actions.questions.findItems({ query: { ...baseQuery, $limit: showTotal } });
  }

  const loadMore = () => {
    props.actions.questions.findItems({ query: { ...baseQuery, $limit: showTotal + PAGE_STEP } });
    setShowTotal(st => st + PAGE_STEP);
  }

  useEffect(() => {
    props.actions.modules.getItem(moduleId);
    initialLoad();

    // Subscribe to real-time events
    FeathersHelper.app.service('questions').on('created', initialLoad);
    FeathersHelper.app.service('questions').on('updated', initialLoad);
    FeathersHelper.app.service('questions').on('patched', initialLoad);

    return function cleanup() {
      FeathersHelper.app.service('questions').removeListener('created', initialLoad);
      FeathersHelper.app.service('questions').removeListener('updated', initialLoad);
      FeathersHelper.app.service('questions').removeListener('patched', initialLoad);
    };
  }, []);

  const confirm = useConfirm();

  return (
    <Container maxWidth="xl">
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Pregunta</TableCell>
              <TableCell align="right">Usuario</TableCell>
              <TableCell align="right">Hora</TableCell>
              {props.master && <TableCell align="center">Mostrar</TableCell>}
              {props.master && <TableCell align="right">Acciones</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.questions.items.map((q) => (
              <TableRow key={q._id}>
                <TableCell component="th" scope="row">
                 {q.content}
                </TableCell>
                <TableCell align="right">{q.user ? `${q.user.firstName} ${q.user.lastName}` : 'Anónimo'}</TableCell>
                <TableCell align="right">{nativeMoment(q.createdAt).format('L HH:mm')}</TableCell>
                {props.master &&
                  <React.Fragment>
                    <TableCell align="center">
                      <Switch
                        color="primary"
                        name="checkedA"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        onChange={(event) =>
                          props.actions.questions.patch(q, {
                            tailored: event.target.checked,
                          })
                        }
                        checked={q.tailored}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        style={{ backgroundColor: '#EE8888'}}
                        onClick={() => {
                          confirm({
                            title: "¿Eliminar pregunta?",
                            description: `Esta acción eliminará por completo la
                            pregunta.`,
                            confirmationText: "Eliminar",
                            confirmationButtonProps: { variant: "contained" },
                            cancellationText: "Cancelar",
                            cancellationButtonProps: { variant: "contained" },
                          })
                            .then(() => {
                              props.actions.questions.remove(q);
                            })
                            .catch(() => {});
                        }}
                      >Eliminar</Button>
                    </TableCell>
                  </React.Fragment>
                }
              </TableRow>
            ))}
          </TableBody>
          {props.data.questions.items.length < props.data.questions.total &&
            <TableFooter>
              <TableCell colSpan={props.master ? 5 : 3}>
                <Button onClick={() => loadMore()} color="primary">Cargar más</Button>
              </TableCell>
            </TableFooter>
          }
        </Table>
      </TableContainer>
    </Container>
  );
};

export default connect(
  (state, ownProps) => ({
    modules: state.data.modules,
    data: {
      questions: state.data.questions
    },
  }),
  (dispatch) => ({
    actions: {
      modules: bindActionCreators(modulesActionCreators, dispatch),
      questions: bindActionCreators(questionsActionCreators, dispatch),
    },
  })
)(QuestionReport);