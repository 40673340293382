import React, { useState } from 'react';

import {
  InputLabel,
  FormControl,
  Select,
  MenuItem
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { useDropzone } from "react-dropzone";

import { scaledImageSource } from "../../../../../../components/ScaledImage";

import FeathersHelper from '../../../../../../helpers/feathers';

const useStyles = makeStyles((theme) => ({
  imageDrop: {
    marginTop: 20,
    marginBottom: 10,
    backgroundColor: "#FAFAFA",
    border: "1px solid #EEEEEF",
    overflow: "hidden",
    position: 'relative'
  },
  fileDropInner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& p": {
      color: "#BCBCBF",
      fontSize: '0.75rem',
      textAlign: 'center',
      maxWidth: '90%'
    },
  },
  imageDropInner: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "& p": {
      color: "#BCBCBF",
      fontSize: '0.75rem',
      textAlign: 'center',
      width: 90,
      maxWidth: '90%'
    },
  },
}));

export default props => {

  const [uploading, setUploading] = useState(false);

  const classes = useStyles();

  const onDrop = (acceptedFiles) => {

    setUploading(true);

    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => {
        setUploading(true);
        console.log("file reading was aborted")
      };
      reader.onerror = () => {
        setUploading(false);
        console.log("file reading has failed")
      };
      reader.onload = () => {
        // FEATHERS STUFF
        const uploadService = FeathersHelper.app.service("uploads");
        var upload = uploadService
          .create({ uri: reader.result })
          .then(function (response) {
            // success
            setUploading(false);
            console.log("Server responded with: ", response);
            props.onChange( { target: { value: response.id } } );
          })
          .catch( e => {
            alert(JSON.stringify(e));
            setUploading(false);
          });
      };
      console.log("THE RECEIVED FILE", file);
      reader.readAsDataURL(file);

    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const value = props.value();

  return (
    <React.Fragment>
      <InputLabel className={["MuiInputLabel-shrink", props.classes.label]}>
        {props.definition.label}
      </InputLabel>
      <div
        className={classes.imageDrop}
        style={{
          paddingTop: `${(1 / props.definition.aspectRatio) * 100}%`,
          position: "relative",
          borderRadius: props.definition.borderRadius,
        }}
        {...getRootProps()}
      >
        <div
          className={classes.imageDropInner}
          style={
            value && {
              backgroundImage: `url(${scaledImageSource(
                value,
                props.definition.width,
                props.definition.height,
                true
              )})`,
            }
          }
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : value ? null : uploading ? (
            <p>Subiendo...</p>
          ) : (
            <p>Drag 'n' drop or select files</p>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};