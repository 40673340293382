import React, { useState } from 'react';

// OWN ROOT COMPONENTS
import {
  Paper,
  Box,
  Button
} from "@material-ui/core/";
import ReactCountryFlag from "react-country-flag";
import ReactQuill from 'react-quill';
import MDEditor from '@uiw/react-md-editor';

import FormField from "../../../../components/FormField";

// WRAPPERS AND CONTEXT CREATORS

// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";

// OTHER UTILS
import defaults from '../../../../defaults.json';
const _ = require("lodash");


// STYLING
const styles = {};

const Component = ({ contents, setContents, selectedPath, ...props}) => {

  const [currentLang, setCurrentLang] = useState(props.selectedApp.langPattern.default);

  const valueForPath = (path) => _.get(contents, path)||'';

  const setValueForPath = (path, value) => setContents(oldContents => {
    const copy = { ...oldContents };
    _.set(copy, path, value);
    console.log(path);
    return Object.values(copy);
  });

  return (
    <Paper className="Language" style={{ borderRadius: 15 }}>
      <div className="Picker" style={{ backgroundColor: 'transparent' }}>
        <Box px={4} pt={2}>
          {defaults.availableLanguages.filter((al) => props.selectedApp.langPattern.available.indexOf(al.code) != -1).map((lang) => (
            <button
              type="button"
              className={`FlagButton ${
                currentLang === lang.code ? "active" : null
              }`}
              onClick={() => setCurrentLang(lang.code)}
            >
              <ReactCountryFlag
                countryCode={lang.flag}
                className="Icon"
                svg
              />
            </button>
          ))}
        </Box>
      </div>
      <Box pb={1}>
        <Box m={3}>
          <FormField
            id={`field_title`}
            error={false}
            definition={{
              key: "title",
              type: "string",
              label: "Título del item",
              help: "Título del item en la lista y en la cabecera",
            }}
            size={12}
            value={valueForPath(`${selectedPath}.title.${currentLang}`)}
            onChange={(e) => {
              setValueForPath(`${selectedPath}.title.${currentLang}`, e.target.value);
            }}
          />
        </Box>

        {valueForPath(`${selectedPath}.contentType`) === 'external' &&
          <Box m={3}>
            <FormField
              id={`field_content`}
              error={false}
              definition={{
                key: "content",
                type: "string",
                label: "URL de destino",
                help: "URL que se abrirá en un webview",
              }}
              size={12}
              value={valueForPath(`${selectedPath}.content.${currentLang}`)}
              onChange={(e) => {
                setValueForPath(`${selectedPath}.content.${currentLang}`, e.target.value);
              }}
            />
          </Box>
        }
        {valueForPath(`${selectedPath}.contentType`) === 'attachment' &&
          <Box m={3}>
            <FormField
              id={`field_content`}
              error={false}
              definition={{
                key: "content",
                type: "file",
                label: "Archivo adjunto",
                help: "El usuario podrá descargar, ver y compartir el archivo en su dispositivo",
              }}
              size={12}
              value={valueForPath(`${selectedPath}.content.${currentLang}`)}
              onChange={(e) => {
                setValueForPath(`${selectedPath}.content.${currentLang}`, e.target.value);
              }}
            />
          </Box>
        }
        {valueForPath(`${selectedPath}.contentType`) === 'general' &&
          <Box m={3}>
            <MDEditor
              value={valueForPath(`${selectedPath}.content.${currentLang}`)}
              onChange={(v) => {
                setValueForPath(`${selectedPath}.content.${currentLang}`, v);
              }}
            />
          </Box>
        }
        <Box m={3}>
          <Button variant="outlined">Eliminar</Button>
        </Box>

      </Box>
    </Paper>
  );
}

export default connect(
  (state) => ({
    selectedApp: state.data.apps.selected,
    services: {
      app: state.services.app,
    },
  }),
  null
)(Component);
