import React, { useState, useEffect } from 'react';

import {
  Typography,
  Modal,
  Box,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Grid,
  Fade,
  Backdrop
} from "@material-ui/core";

import Field from './components/Field';

import ReactCountryFlag from "react-country-flag";

import { connect } from "react-redux";

import defaults from '../../defaults.json';

const _ = require("lodash");

const RightModalV2 = props => {

  const isNew = !props.currentItem._id;

  const [currentLang, setCurrentLang] = useState(props.selectedApp.langPattern.default);
  const [newObject, setNewObject] = useState({});
  const [erroredFields, setErroredFields] = useState({});

  useEffect(() => {
    setNewObject(props.currentItem);
  }, [props.currentItem]);

  const valueForPath = (path) => _.get(newObject, path);

  const setValueForPath = (path, value) => setNewObject(newObject => {

    const copy = { ...newObject };
    _.set(copy, path, value);
    if (props.dependencies && props.dependencies[path] && props.dependencies[path][value]){
      _.set(copy, props.dependencies[path][value].path, props.dependencies[path][value].value);
    }
    return copy;
  });

  const create = () => {
    setErroredFields({});
    props.createAction(newObject)
      .then((r) => {
        props.handleClose();
      })
      .catch((e) => {
        if (e.errors) {
          Object.keys(e.errors).map((k) => {
            let message;
            if (e.errors[k] == "value") {
              message = "Ya existe, debe ser único";
            } else {
              switch (e.errors[k].kind) {
                case "required":
                  message = "Este campo es obligatorio";
              }
            }
            if (k.indexOf(".") === -1) {
              setErroredFields((ef) => ({ ...ef, [k]: message }));
            } else {
              let base = [];
              k.split(".").map((p) => {
                base.push(p);
                setErroredFields((ef) => ({
                  ...ef,
                  [base.join(".")]: message,
                }));
              });
            }
          });
        }

      });
  };

  const patch = () => {
    props.patchAction(props.currentItem, newObject)
      .then((r) => {
        props.handleClose();
      })
      .catch((e) => {
        console.log("Patch error", e);
      });
  }

  const handleSave = () => {
    if (isNew) create();
    else patch();
  }

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        {props.contentOverrideEnabled ? (
          props.contentOverride
        ) : (
          <div className="RightModal">
            <Box px={4} py={2.5}>
              <Typography component="h3" variant="h3">
                {isNew ? props.newTitle : props.editTitle}
              </Typography>
            </Box>
            <div className="Center">
              <Paper className="Language">
                <div className="Picker">
                  <Box px={4} pt={2}>
                    {defaults.availableLanguages.filter((al) => props.selectedApp.langPattern.available.indexOf(al.code) != -1).map((lang) => (
                      <button
                        type="button"
                        className={`FlagButton ${
                          currentLang === lang.code ? "active" : null
                        }`}
                        onClick={() => setCurrentLang(lang.code)}
                      >
                        <ReactCountryFlag
                          countryCode={lang.flag}
                          className="Icon"
                          svg
                        />
                      </button>
                    ))}
                  </Box>
                </div>
                <Box p={4}>
                  <Grid container spacing={2}>
                    {props.manifest.map((row) => {
                      const size = row.length == 1 ? 12 : 6;
                      return row.map((i) => (
                        <Field
                          id={i.multilang ? `field_${i.key}_${currentLang}` : `field_${i.key}`}
                          definition={i}
                          size={size}
                          basePath={[]}
                          currentLang={currentLang}
                          erroredFields={erroredFields}
                          valueForPath={valueForPath}
                          setValueForPath={setValueForPath}
                          //error={erroredFields[i.key]}
                          // value={() => {
                          //   if (i.multilang) return valueForPath(`${i.key}.${currentLang}`) || ''
                          //   else return valueForPath(i.key)
                          // }}
                          // onChange={(e) => {
                          //   if (i.multilang) setValueForPath(`${i.key}.${currentLang}`, e.target.value);
                          //   else setValueForPath(i.key, e.target.value);
                          // }}
                        />
                      ));
                    })}
                  </Grid>
                </Box>
              </Paper>
            </div>

            <Paper className="Footer">
              <Box p={4}>
                <Button variant="outlined" onClick={() => props.handleClose()}>
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  disabled={false}
                  className="SaveButton"
                  onClick={handleSave}
                >
                  Guardar
                </Button>
              </Box>
            </Paper>
          </div>
        )}
      </Fade>
    </Modal>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
  }), null
)(RightModalV2);