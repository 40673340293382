import React, {} from 'react';

import {
  Grid,
  FormControl,
  Select,
  TextField
} from "@material-ui/core";

import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import DatePickerWithTimezone from './components/DatePickerWithTimezone';
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import nativeMoment from "moment-timezone";

import timezones from './timezones';

registerLocale('es', es)
setDefaultLocale(es);

export default props => {

  const ExampleCustomInput = ({ value, onClick }) => (
    <TextField
      fullWidth
      label={`${props.definition.label}${props.definition.multilang ? ` (${props.currentLang})` : ''}`}
      InputLabelProps={{
        shrink: true,
        className: props.classes.label,
      }}
      placeholder={props.definition.label}
      helperText={props.error || props.definition.help}
      FormHelperTextProps={{
        className: `${props.classes.helperText} ${
          props.error ? props.classes.error : null
        }`,
      }}
      value={value}
      onClick={onClick}
    />
  );

  return (
    <Grid container>
      <Grid item md={6}>
        <DatePickerWithTimezone
          selected={props.value()}
          locale="es"
          onChange={ v => {
            props.onChange({ target: { value: v } })
          }}
          timezone={props.timezoneValue}
          showTimeSelect
          timeIntervals={5}
          dateFormat="dd/MM/yyyy HH:mm"
          customInput={<ExampleCustomInput />}
          style={{ width: '100%'}}
        />
      </Grid>
      <Grid item md={6}>
        <FormControl
          variant="outlined"
          className={props.classes.formControl}
          fullWidth
        >
          <Select
            native
            displayEmpty
            value={props.timezoneValue}
            onChange={props.onTimezoneChange}
          >
            {timezones.map(tz => (
              <option key={tz} value={tz}>{tz}</option>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};